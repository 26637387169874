import {
    SET_LOGIN,
    SET_SUBSCRIPTION,
    SET_FILES_SELECTED,
    GET_HTTP_LOG_DATA,
    SET_LANGUAGE,
    SET_UPLOAD_EXPIRY,
    SET_UPLOAD_MEDIUM,
    SET_UPLOAD_PASSWORD,
    SET_UPLOAD_SECURITY_TYPE,
    GET_META,
    GET_USER,
    GET_USER_UPLOADS,
    GET_STRIPE_SESSION,
} from "../constants";

// ============================================================================

const initialState = {
    // UPLOAD
    filesSelected: undefined,
    fileUploadSetting: {
        medium: 'internet',
        password: null,
        securityType: 'UPLOAD_SECURITY_SIMPLE', // TODO: replace with default
        privacyType: 'public',
        storage: {
            provider: 'AWS S3',
            location: 'London Region',
        },
        expiry: {
            // TODO: get the default option from meta
            ms: 1 * 60 * 60 * 1000, // 5 min, 1 hour, 1 day, 3 days, 10 days, custom
            downloads: 1,
            views: 10,
        },
        sizeLimitGb: {
            file: 4, // note: AWS maximum is 5TB per object (https://www.npmjs.com/package/s3-upload-resume)
            account: 50,
        },
        sizeLimitFileNumber: 10000,
        speedLimitGbps: 2.3,
    },

    // DOWNLOAD
    fileDownloadSetting: {
        canDelete: 'no',
        speedLimitGbps: 25.0,
        linkType: 'wacky.link',
    },

    user: {},
    username: localStorage && localStorage.getItem('username'),
    subscription: null,
    isLoggedIn: !!localStorage.getItem('username'),

    localeOptions: ['en'],
    localeCurrent: 'en',
    locale: {},

    uploadedTotalGb: 0,
    uploadExpiryOptions: [],
    uploadSecurityOptions: [],

    checkoutLink: null,
};

// ============================================================================

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FILES_SELECTED:
            return {
                ...state,
                filesSelected: action.payload
            };
        case SET_LANGUAGE:
            return {
                ...state,
                locale: action.payload.locale,
                localeCurrent: action.payload.language
            }
        case GET_META:
            return {
                ...state,
                localeOptions: action.payload.locales,
                uploadSecurityOptions: action.payload.fileSecurityOptions,
                uploadExpiryOptions: action.payload.fileExpiryOptions,
                uploadMediumOptions: action.payload.fileUploadMediumOptions,
                uploadedTotalGb: action.payload.totalSizeGB,
            }
        case GET_USER:
            return {
                ...state,
                user: action.payload || {},
            }
        case GET_USER_UPLOADS:
            return {
                ...state,
                filesUploaded: action.payload.uploads,
            }
        case SET_LOGIN:
            return {
                ...state,
                isLoggedIn: !!action.payload.username && !!action.payload.token,
                username: action.payload.username,
            }
        case SET_SUBSCRIPTION:
            return {
                ...state,
                subscription: action.payload.subscription,
            }    
        case SET_UPLOAD_EXPIRY:
            return {
                ...state,
                fileUploadSetting: {
                    ...state.fileUploadSetting,
                    expiry: {
                        ...state.fileDownloadSetting.expiry,
                        ms: action.payload
                    }
                }
            }
        case SET_UPLOAD_MEDIUM:
            return {
                ...state,
                fileUploadSetting: {
                    ...state.fileUploadSetting,
                    medium: action.payload.medium,
                }
            }
        case SET_UPLOAD_PASSWORD:
            return {
                ...state,
                fileUploadSetting: {
                    ...state.fileUploadSetting,
                    password: !!action.payload.password ? action.payload.password : null,
                }
            }
        case SET_UPLOAD_SECURITY_TYPE:
            return {
                ...state,
                fileUploadSetting: {
                    ...state.fileUploadSetting,
                    securityType: !!action.payload.securityType ? action.payload.securityType : null,
                }
            }  
        case GET_STRIPE_SESSION:
            const checkoutLink = action.payload.data.url;

            return {
                ...state,
                checkoutLink,
            }
        default:
            return state;
    }
}

export default rootReducer;