import React, { useState, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { connect } from 'react-redux';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import { getMeta, getUser, setLanguage } from "./store/actions";
import { redirectCrossBrowser } from "./services/utils.js";

import "./styles/theme.css";
import "./styles/master.css";
import PageUpload from "./components/Layout/PageUpload.js";
import PageDownload from "./components/Layout/PageDownload.js";
import PageUploadManager from "./components/Layout/PageUploadManager.js";
import PagePricing from "./components/Layout/PagePricing.js";

import PageLogin from "./components/Layout/PageLogin.js";

import PageSubscriptionStatus from "./components/Layout/PageSubscriptionStatus.js";

import PageFeatures from "./components/Layout/PageFeatures.js";
import PageReportAbuse from "./components/Layout/PageReportAbuse.js";
import PageSettings from "./components/Layout/PageSettings.js";
import PageLegal from "./components/Layout/PageLegal.js";
import PageFAQ from "./components/Layout/PageFAQ.js";
import PageContact from "./components/Layout/PageContact.js";

import PageToS from "./components/Layout/PageToS.js";
import PagePrivacy from "./components/Layout/PagePrivacy.js";

// simple nice things to have ;)
import "./webSockets.js";
import "./wackyEaster.js"

// ============================================================================

const theme = createTheme({
    palette: {
        info: {
            light: '#000000',
            main: '#000000',
            dark: '#000000'
          },
          primary: {
            light: '#000000',
            main: '#000000',
            dark: '#000000'
          }
            }
});

const App = (props) => {
    const styleWrapper = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
    }

    const [styleWrapperContent] = useState({
        // maxWidth: '420px',
        // width: '90%',
    })

    useEffect(() => {
        props.getMeta();
        props.getUser();
        props.setLanguage('en');
    }, []);

    return (
        <MuiThemeProvider theme={theme}>            
            <div style={styleWrapper} >
                <BrowserRouter>
                    <div style={styleWrapperContent} className="container__default">
                        <Routes>

                            {/* CORE FLOW */}

                            <Route exact path="/" element={<PageUpload />} />
                            <Route exact path="/u/:uploadHash" element={<PageDownload />} />
                            <Route exact path="/uploads" element={<PageUploadManager />} />
                            <Route exact path="/settings" element={<PageSettings />} />

                            {/* AUTH FLOW */}

                            {/* <Route exact path="/auth/login" element={<PageLogin />} />
                            <Route exact path="/auth/register" element={<PageLogin />} />
                            <Route exact path="/auth/register/:registerHash" element={<PageLogin />} />

                            <Route exact path="/auth/confirm-email" element={<PageLogin />} />
                            <Route exact path="/auth/confirm-email-success" element={<PageLogin />} />
                            <Route exact path="/auth/confirm-email-session-expired" element={<PageLogin />} />

                            <Route exact path="/auth/forgot-password" element={<PageLogin />} />
                            <Route exact path="/auth/forgot-password-success" element={<PageLogin />} />
                            <Route exact path="/auth/forgot-password-session-expired" element={<PageLogin />} />
                            <Route exact path="/auth/reset-password" element={<PageLogin />} />
                            <Route exact path="/auth/reset-password/:resetHash" element={<PageLogin />} />
                            <Route exact path="/auth/reset-password-success" element={<PageLogin />} />

                            <Route exact path="/auth/logout" element={<PageLogin />} />

                            <Route exact path="/auth/session-expired" element={<PageLogin />} />
                            <Route exact path="/auth/error" element={<PageLogin />} /> */}

                            {/* more specific paths first */}
                            <Route exact path="/auth/register/:registerHash" element={<PageLogin />} />
                            <Route exact path="/auth/reset-password/:resetHash" element={<PageLogin />} />
                            <Route exact path="/auth/:loginType" element={<PageLogin />} />

                            {/* SUBSCRIPTION FLOW */}

                            <Route exact path="/subscription/success" element={<PageSubscriptionStatus status="success" />} />
                            <Route exact path="/subscription/cancelled" element={<PageSubscriptionStatus status="cancelled" />} />

                            {/* MARKETING FLOW */}

                            <Route exact path="/pricing" element={<PagePricing />} />
                            {/* TODO: implement the features page */}
                            <Route exact path="/features" element={<PagePricing />} />
                            {/* <Route exact path="/features" element={<PageFeatures />} /> */}

                            {/* LEGAL FLOW */}

                            <Route exact path="/legal" element={<PageLegal />} />
                            <Route exact path="/faq" element={<PageFAQ />} />

                            {/* TODO: remove those two? (think about it) */}
                            {/* <Route exact path='/tos' element={<PageToS />} /> */}
                            {/* <Route exact path='/privacy-policy' element={<PagePrivacy />} /> */}

                            <Route exact path="/report" element={<PageReportAbuse />} />
                            <Route exact path="/contact" element={<PageContact />} />
                            <Route path="*" element={<Navigate to="/" />} />

                        </Routes>
                    </div>
                </BrowserRouter>
            </div>
        </MuiThemeProvider>
    );
};

export default connect(null, { getMeta, getUser, setLanguage })(App);