import "./FileUploaderSettings.css";

// core
import React, { useState } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

// TODO: expose this duplicate code around password into a separate component
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { utilValidatePassword } from "../AuthLogin/validationAuth.js";

// custom stuff
import { setUploadPassword, setUploadExpiry, setUploadMedium, setUploadSecurity } from "../../store/actions";

// ============================================================================

const UploadFilesSettings = (props) => {
  // TODO: expose this duplicate code around password into a separate component

  // password
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [validationPassword, setValidationPassword] = useState(true)
  const [validationPasswordMessage, setValidationPasswordMessage] = useState('')

  const handleChangePassword = (event) => {
    const passwordNew = event.target.value;
    validatePassword(passwordNew)
    setPassword(passwordNew);
    props.setUploadPassword(passwordNew && passwordNew.trim());
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const validatePassword = (password) => {
    setValidationPassword(true)
    setValidationPasswordMessage(null);

    if (password.length > 0) {
      const { success, messageUser } = utilValidatePassword(password)
      setValidationPassword(success)
      setValidationPasswordMessage(messageUser)
    }
  }

  // ----------------------------------------------------------------

  const handleSecurityOption = (securityOption) => {
    props.setUploadSecurity(securityOption);
  }

  const handleExpiryOption = (expiryMs) => {
    props.setUploadExpiry(expiryMs);
  }

  const handleMediumOption = (mediumData) => {
    props.setUploadMedium(mediumData);
  }

  return (
    <div className="mg20 advanced-config">
      {props.fileUploadSetting && props.locale.UPLOAD___SETTING && props.locale.DOWNLOAD___SETTING && (
        <ul className="list-group" style={{ width: '100%' }}>
          {/* <div className="advanced-config__item">
            <div className="advanced-config__item__heading">{props.locale.UPLOAD___SETTING.PASSWORD.title}</div>
            <div className="advanced-config__item__heading-secondary">{props.fileUploadSetting.password || 'none'}</div>
          </div>
          <div className="advanced-config__item">
            <div className="advanced-config__item__heading">{props.locale.DOWNLOAD___SETTING.CAN_DELETE.title}</div>
            <div className="advanced-config__item__heading-secondary">{props.fileDownloadSetting.canDelete || false}</div>
          </div> */}
          <div className="advanced-config__item">
            {(props.uploadMediumOptions.length > 1) && (
              <div>
                <div className="advanced-config__item__heading">{props.locale.UPLOAD___SETTING.SHARING_TYPE.title}</div>
                <div className="advanced-config__item__options">
                  {props.uploadMediumOptions.map(mediumOption => (
                    <div
                      key={mediumOption.id}
                      className={`advanced-config__item__option ${ props.fileUploadSetting.medium === mediumOption.medium ? 'advanced-config__item__option-current' : ''}`}
                      style={{ textAlign: 'center', fontSize: '12px', float: 'left', width: `${100 / props.uploadMediumOptions.length}%` }}
                      onClick={() => handleMediumOption(mediumOption)}>
                      {props.locale[mediumOption.id]}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {(props.uploadExpiryOptions.length > 1) && (
              <div className="advanced-config__wrapper">
                <div className="advanced-config__item__heading">{props.locale.UPLOAD___SETTING.STORAGE_EXPIRY.title}</div>
                <div className="advanced-config__item__options">
                  {props.uploadExpiryOptions.map(expiryOption => (
                    <div
                      key={expiryOption.id}
                      className={`advanced-config__item__option ${ props.fileUploadSetting.expiry.ms === expiryOption.expiryMs ? 'advanced-config__item__option-current' : ''}`}
                      style={{ textAlign: 'center', fontSize: '12px', float: 'left', width: `${100 / props.uploadExpiryOptions.length}%` }}
                      onClick={() => handleExpiryOption(expiryOption.expiryMs)}>
                      {props.locale[expiryOption.id]}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* TODO: make password visible only if you are allowed to use this feature as a user */}
            {(props.uploadSecurityOptions.length >= 1) && (
              <div>
                <div className="advanced-config__item__heading">Security Settings</div>
                <div className="advanced-config__item__options">
                  {props.uploadSecurityOptions.map(expiryOption => (
                    <div
                      key={expiryOption.id}
                      className={`advanced-config__item__option ${ props.fileUploadSetting.securityType === expiryOption.id ? 'advanced-config__item__option-current' : ''}`}
                      style={{ textAlign: 'center', fontSize: '12px', float: 'left', width: `${100 / props.uploadSecurityOptions.length}%` }}
                      onClick={() => handleSecurityOption(expiryOption.id)}>
                      {expiryOption.title}
                    </div>
                  ))}
                </div>

                {(props.fileUploadSetting.securityType === 'UPLOAD_SECURITY_SIMPLE') && (
                  <div></div>
                )}
                {(props.fileUploadSetting.securityType === 'UPLOAD_SECURITY_ZEROKNOWLEDGE') && (
                  <div></div>
                )}
                {(props.fileUploadSetting.securityType === 'UPLOAD_SECURITY_PASSWORD') && (
                  <div>
                    <div className="button-login__password-show-toggle">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {!showPassword && (
                          <Visibility
                            className="icon-login icon-login__password-show-toggle-on"
                            aria-label="toggle password visibility"
                          ></Visibility>
                        )}
                        {!!showPassword && (
                          <VisibilityOff
                            className="icon-login icon-login__password-show-toggle-off"
                            aria-label="toggle password visibility"
                          ></VisibilityOff>
                        )}
                      </IconButton>
                    </div>
                    <div className="data__login__password-wrapper">
                      <TextField
                        error={!validationPassword}
                        helperText={validationPasswordMessage}

                        fullWidth
                        placeholder="Enter your password for this upload"

                        label="Password (optional)"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handleChangePassword}
                        variant="outlined"
                        margin="dense"
                        color="secondary"

                        className="data__login__password"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {!props.isLoggedIn && (
            <div className="advanced-config__links-wrapper">
              <div className="advanced-config__links">
                We have Zero-Knowledge / password-protected uploads and much more! Just have a look at our <span><Link className="advanced-config__link" to="/features">features</Link></span> and <span><Link className="advanced-config__link" to="/pricing">plans</Link></span>.
              </div>
              <div className="advanced-config__links">
                <span><Link className="advanced-config__link" to="auth/login">Sign in</Link></span> or <span><Link className="advanced-config__link" to="auth/register">Create an account</Link></span> today!
              </div>
            </div>
          )}
        </ul>
      )}
    </div >
  );
}

// ============================================================================

// map some state actions and state reads into props
const mapDispatchToProps = (dispatch) => {
  return {
    setUploadExpiry: (file) => dispatch(setUploadExpiry(file)),
    setUploadMedium: (mediumData) => dispatch(setUploadMedium(mediumData)),
    setUploadPassword: (password) => dispatch(setUploadPassword(password)),
    setUploadSecurity: (securityType) => dispatch(setUploadSecurity(securityType)),
  };
};

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    isLoggedIn: state.isLoggedIn,
    fileUploadSetting: state.fileUploadSetting,
    fileDownloadSetting: state.fileDownloadSetting,
    uploadMediumOptions: state.uploadMediumOptions.filter(option => {
      // TODO: implement proper levels
      const level = state.isLoggedIn ? 1 : 0
      return option.level <= level
    }),
    uploadSecurityOptions: state.uploadSecurityOptions.filter(option => {
      // TODO: implement proper levels
      const level = state.isLoggedIn ? 2 : 2
      return option.level <= level
    }),
    uploadExpiryOptions: state.uploadExpiryOptions.filter(option => {
      // TODO: implement proper levels
      const level = state.isLoggedIn ? 1 : 0
      return option.level <= level
    }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadFilesSettings)