import axios from 'axios';
import { getRequestOptsAuth } from "./utils.js"

// ============================================================================

// Encapsulate the way you're making requests (makes it a lot easier to tweak globally)
const apiClient = axios.create({
    // baseURL: "http://localhost:8000", // localhost
    // proxy: {
    //   port: 8000
    // },
    headers: {
      "Content-type": "application/json"
    }
});

// ============================================================================

const ContactService = {
  reportAbuse: async ({ linkAbuse, textDetails }) => {
    const res = await apiClient.post("/api/v1/contact/report-abuse", { linkAbuse, textDetails });
    console.log(res.data);
    return res.data;
  },
  contact: async ({ email, textSubject, textDetails }) => {
    const opts = getRequestOptsAuth();
    const res = await apiClient.post("/api/v1/contact/general", { email, textSubject, textDetails }, opts);
    console.log(res.data);
    return res.data;
  },
}

export {
    ContactService,
}