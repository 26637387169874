import { saveAs } from 'file-saver';

const computeFileNames = (files) => {
  let names = [];
  for (let i = 0; i < files.length; i += 1) {
    names.push(files[i].name)
  }

  return names.join(',');
}

const formatSize = (size) => {
  let filesize = (size / 1024.0).toFixed(1);
  let filesizeType = 'kb'; // locale.MISC___SIZE_KB;

  if (filesize >= 1000) {
    filesize = (filesize / 1024.0).toFixed(1);
    filesizeType = 'Mb'; // locale.MISC___SIZE_MB;
  }

  if (filesize >= 1000) {
    filesize = (filesize / 1024.0).toFixed(1);
    filesizeType = 'Gb'; // locale.MISC___SIZE_GB;
  }

  if (filesize >= 1000) {
    filesize = (filesize / 1024.0).toFixed(1);
    filesizeType = 'Tb'; // locale.MISC___SIZE_TB;
  }

  return `${filesize}${filesizeType}`; // ex: 523.3kb
}

// TODO: add locales
const computeFileSizeFormatted = (files) => {
  let size = 0;
  for (let i = 0; i < files.length; i += 1) {
    size += files[i].size
  }

  return formatSize(size);
}

// source: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
};

// source: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
}

// we need this because browser BLOB have quite low limits (~800mb) and download attribute doesn't always work
// saveAs(url, filename);
// unfortunately this approach does not really work so we need to do it via special config for signed urls on the backend
const saveFileAs = (url, filename) => {
  saveAs(url);

  // https://github.com/eligrey/FileSaver.js/issues/557
  // https://stackoverflow.com/questions/54545754/nodejs-react-download-file-from-s3-bucket-using-pre-signed-url
  // const link = document.createElement("a");
  // link.target = "_blank";
  // link.href = url;
  // link.setAttribute('download', filename);
  // document.body.appendChild(link);
  // link.click();
}

const blobToBase64 = async (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

// get auth headers for the secure API requests
const getRequestOptsAuth = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return {
      headers: {
        authorization: token
      }
    }
  }

  return null;
}

const stringToColor = (str, saturation = 100, lightness = 25) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash;
  }
  return `hsl(${(hash % 360)}, ${saturation}%, ${lightness}%)`;
}

// TODO: replace with the user ID
const generateAvatarColorBg = (username, lightness = 25) => {
  // return stringToColor(username, 100, lightness);
  return '#5e02a5'; // generative colors sometimes generate crap
}

const generateAvatarColorText = (username) => {
  return '#FFFFFF';
}

const generateAvatarInitials = (username) => {
  const usernameNoEmail = username.split('@')[0];
  const splitters = '_-.'.split('');
  const pieces = splitters.reduce((res, splitter, i) => i === 0 ? res : res.split(splitters[i - 1]).join(splitters[i]), usernameNoEmail).split(splitters[splitters.length-1]);

  if (pieces.length > 1) {
      return `${pieces[0][0].toUpperCase()}${pieces[1][0].toUpperCase()}`;
  }

  if (pieces[0].split('').length > 1) {
      return `${pieces[0][0].toUpperCase()}${pieces[0][1].toUpperCase()}`;
  }

  return pieces[0][0].toUpperCase();
}

// copied from https://stackoverflow.com/questions/503093/how-do-i-redirect-to-another-webpage
const redirectCrossBrowser = (url) => {
  var ua        = navigator.userAgent.toLowerCase(),
      isIE      = ua.indexOf('msie') !== -1,
      version   = parseInt(ua.substr(4, 2), 10);

  // Internet Explorer 8 and lower
  if (isIE && version < 9) {
      var link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
  }

  // All other browsers can use the standard window.location.href (they don't lose HTTP_REFERER like Internet Explorer 8 & lower does)
  else { 
      window.location.href = url; 
  }
}

export {
  copyTextToClipboard,
  computeFileNames,
  computeFileSizeFormatted,
  formatSize,
  saveFileAs,
  blobToBase64,
  getRequestOptsAuth,
  generateAvatarColorBg,
  generateAvatarColorText,
  generateAvatarInitials,
  redirectCrossBrowser,
}