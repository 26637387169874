import "./ProgressBar.css";

import React, { useState, useEffect } from "react";
import { Typography, LinearProgress, withStyles } from '@material-ui/core';

// ============================================================================

const BorderLinearProgress = withStyles(() => ({
    root: { height: 15, borderRadius: 5 },
    colorPrimary: { backgroundColor: "var(--color__bg-secondary)" },
    bar: { borderRadius: 5, backgroundColor: 'var(--color__surface__action)' }, // TODO: import CSS variable here
}))(LinearProgress);

export default function ProgressBar(props) {
    const [progress, setProgress] = useState(parseInt(props.progress));

    useEffect(() => {
        setProgress(parseInt(props.progress));
    }, [props]);

    return (
        <div>
            <BorderLinearProgress variant="determinate" value={progress} />
            <Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography>
        </div >
    );
}