import "./PagePricing.css";

import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { getPaymentStripeSession } from "../../store/actions";
import { redirectCrossBrowser } from "../../services/utils.js";
import GeneralHeader from "./GeneralHeader/GeneralHeader.js";
import GeneralFooter from "./GeneralFooter/GeneralFooter.js";

// ============================================================================

const PagePricing = (props) => {
  let history = useNavigate();
  const [checkoutLink, setCheckoutLink] = useState(props.checkoutLink);

  const handleClickSubscribeYOLO = () => {
    props.getPaymentStripeSession('YOLO');
  }

  const handleClickSubscribeMINI = () => {
    props.getPaymentStripeSession('MINI');
  }

  const handleClickSubscribePOSH = () => {
    props.getPaymentStripeSession('POSH');
  }

  const formatSubscription = (user) => {
    if (!user || !user.limits) {
      return 'No Plan';
    }

    return user.limits.id;
  }

  const handleClickSubscribeSuccess = () => {
    history('/subscription/success');
  }

  const handleClickSubscribeCancel = () => {
    history('/subscription/cancelled');
  }
  
  useEffect(() => {
    if (!!props.checkoutLink && (checkoutLink !== props.checkoutLink)) {
      // redirect to Stripe
      // TODO: cleanup
      redirectCrossBrowser(props.checkoutLink);
    }

    setCheckoutLink(props.checkoutLink);
  }, [props.checkoutLink])

  return (
    // <div className="neumorph__panel panel-wide neumorph__surface-blank__pressed boring-page__default-block">
    <div className="panel-wide">
      <GeneralHeader />
      {!props.isLoggedIn && (
        <div>
          <div className="boring-page__default-title">
            {props.locale.BORING___PRICING}
          </div>
          <div className="boring-page__default-text">
            Something really cool at the price of a McDonalds meal (or two)!
          </div>
        </div>
      )}
      {props.isLoggedIn && (
        <div className="boring-page__default-title">
          Subscription
        </div>
      )}
      <div className="neumorph__panel pricing__card">
        <div className="pricing__card__subscription-title">YOLO</div>
        <div className="pricing__card__subscription-price">FREE</div>
        <div className="pricing__card__subscription-subprice"></div>
        <div className="pricing__card__subscription-description">Create an account and get a taste of MINI and POSH for 3 days straight ;)</div>
        <div className="pricing__card__subscription-features">
          <ul>
            <li><span className="pricin__card__subscription-features-todo">TODO</span>2gb upload traffic / month</li>
            <li><span className="pricin__card__subscription-features-todo">TODO</span>10gb download traffic / month</li>
            <li><span className="pricin__card__subscription-features-todo">TODO</span>10 downloads / link</li>
            <hr/>
            <li><span className="pricin__card__subscription-features-todo">TODO</span>data residency: UK</li>
            <li><span className="pricin__card__subscription-features-todo">TODO</span>no Google Analytics (we use Fathom)</li>
            <li>Strong TLS encryption</li>



            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>IPFS sharing</li>             */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>noJS version</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>WiFi sharing</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>Snow Crash sharing</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>MINI for 3 days</li> */}
          </ul>
        </div>
        {/* {props.isLoggedIn && ( */}
          <div>
            {!props.isLoggedIn && (
              <Link to="/auth/register">
                <button
                  // onClick={handleClickSubscribeMINI}              
                  className="neumorph__surface-action__bump pricing__card__button-subscribe"
                  // disabled
                  >
                  Create an account
                </button>
              </Link>
            )}

            {props.isLoggedIn && (
              <div>
                { formatSubscription(props.user) === 'YOLO' && (
                  <div
                    className="pricing__card__button-active">
                    Active
                  </div>
                )}
                { formatSubscription(props.user) === 'MINI' && (
                  <button
                    onClick={handleClickSubscribeYOLO}
                    className="neumorph__panel pricing__card__button-subscribe">
                    Downgrade
                  </button>            
                )}
                { formatSubscription(props.user) === 'POSH' && (
                  <button
                    onClick={handleClickSubscribeYOLO}
                    className="neumorph__panel pricing__card__button-subscribe">
                    Downgrade
                  </button>            
                )}
              </div>
            )}
            {/* <button
              onClick={handleClickSubscribeMINI}
              className="neumorph__panel neumorph__surface-action__bump pricing__card__button-subscribe">
              Subscribe
            </button> */}
            {/* <button
              onClick={handleClickSubscribeSuccess}              
              className="neumorph__panel neumorph__surface-action__bump pricing__card__button-subscribe">
              Active
            </button> */}
            {/* <button
              onClick={handleClickSubscribeCancel}              
              className="neumorph__panel pricing__card__button-subscribe">
              Subscription cancelled
            </button> */}
          </div>
      </div>
      <div className="neumorph__panel pricing__card">
        <div className="pricing__card__subscription-title">MINI
          {/* <span className="pricing__card__subscription-badge">MOST POPULAR</span> */}
        </div>
        {/* <div className="pricing__card__subscription-price">
          <span className="pricing__card__subscription-price-strike">£4.99 / month</span>
          <span className=""> free in Beta</span>
        </div> */}
        <div className="pricing__card__subscription-price">£4.99 / month</div>
        <div className="pricing__card__subscription-subprice">7 days money-back guarantee</div>
        <div className="pricing__card__subscription-description">Perfect subscription for individuals that occasionally need to share something big with their friends or colleagues.</div>
        <div className="pricing__card__subscription-features">
          <ul>
            <li>200gb upload traffic / month</li>
            <li>500gb download traffic / month</li>
            <li>100 downloads / link</li>
            <hr/>
            <li><span className="pricin__card__subscription-features-todo">TODO</span>Upload manager</li>
            <li>More timing options</li>
            <li><span className="pricin__card__subscription-features-todo">TODO</span>Password-protected uploads</li>



            {/* <li>All YOLO features</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>Watermarking files</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>Select cloud / storage location</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>No link (private mode)</li> */}
            {/* <li><span className="pricin__card__subscription-features-todo">TODO</span>Portals</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>No more captchas</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>cli version</li> */}
          </ul>
        </div>
        {/* {props.isLoggedIn && ( */}
          <div>
            {!props.isLoggedIn && (
              <Link to="/auth/register">
                <button
                  // onClick={handleClickSubscribeMINI}              
                  className="neumorph__surface-action__bump pricing__card__button-subscribe"
                  // disabled
                  >
                  Create an account
                </button>
              </Link>
            )}

            {props.isLoggedIn && (
              <div>
                { formatSubscription(props.user) === 'YOLO' && (
                  <button
                  onClick={handleClickSubscribeMINI}
                  className="neumorph__panel neumorph__surface-action__bump pricing__card__button-subscribe">
                    Upgrade
                  </button>            
                )}
                { formatSubscription(props.user) === 'MINI' && (
                  <div
                  className="pricing__card__button-active">
                    Active
                  </div>
                )}
                { formatSubscription(props.user) === 'POSH' && (
                  <button
                  onClick={handleClickSubscribePOSH}
                  className="neumorph__panel pricing__card__button-subscribe">
                    Downgrade
                  </button>            
                )}
              </div>
            )}
            {/* <button
              onClick={handleClickSubscribeSuccess}              
              className="neumorph__panel neumorph__surface-action__bump pricing__card__button-subscribe">
              Active
            </button> */}
            {/* <button
              onClick={handleClickSubscribeCancel}              
              className="neumorph__panel pricing__card__button-subscribe">
              Subscription cancelled
            </button> */}
          </div>
        {/* )} */}
      </div>

      {/* TODO: add neumorph__surface-action__bump class for an active subscription */}
      {/* TODO: replace "REGISTER" and "SUBSCRIBE" with "UPGRADE" and "DOWNGRADE" when appropriate */}
      {/* <div className="neumorph__panel pricing__card pricing__card-in-development"> */}
      <div className="neumorph__panel pricing__card">
        <div className="pricing__card__subscription-title">POSH</div>
        <div className="pricing__card__subscription-price">£9.99 / month</div>
        <div className="pricing__card__subscription-subprice">7 days money-back guarantee</div>
        <div className="pricing__card__subscription-description">Perfect subscription for individuals who know what's crackin'.</div>
        <div className="pricing__card__subscription-features">
          <ul>
            <li>1Tb upload traffic / month</li>
            <li>2Tb download traffic / month</li>
            <li>500 downloads / link</li>
            <hr/>
            <li><span className="pricin__card__subscription-features-badge">in development</span>[soon] Zero-Knowledge (E2E Encryption)</li>
            <li><span className="pricin__card__subscription-features-badge">in development</span>[soon] Multiple file upload</li>
            <li><span className="pricin__card__subscription-features-badge">in development</span>[soon] Views / Downloads Tracking</li>




            {/* <li>All MINI features</li> */}
            {/* <li>Permanent uploads</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>2FA/MFA Uploads</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>Pause / resume uploads</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>Schedule sending download links</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>Allow downloader to delete files</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>Download page themes</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>Notifications / Tracking per Email</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>Notifications / Tracking per SMS</li> */}
            {/* <li><span className="pricin__card__subscription-features-badge">in development</span>Themes</li> */}
          </ul>
        </div>
        {/* {props.isLoggedIn && ( */}
          <div>
            {!props.isLoggedIn && (
              <Link to="/auth/register">
                <button
                  // onClick={handleClickSubscribeMINI}              
                  className="neumorph__surface-action__bump pricing__card__button-subscribe"
                  // disabled
                  >
                  Create an account
                </button>
              </Link>
            )}
            {/* <button
              // onClick={handleClickSubscribePOSH}              
              className="pricing__card__button-subscribe"
              >
              In development
            </button> */}


            {props.isLoggedIn && (
              <div>
                { formatSubscription(props.user) === 'YOLO' && (
                  <button
                    onClick={handleClickSubscribePOSH}
                    className="neumorph__panel neumorph__surface-action__bump pricing__card__button-subscribe">
                    Upgrade
                  </button>            
                )}
                { formatSubscription(props.user) === 'MINI' && (
                  <button
                    onClick={handleClickSubscribePOSH}
                    className="neumorph__panel neumorph__surface-action__bump pricing__card__button-subscribe">
                    Upgrade
                  </button>
                )}
                { formatSubscription(props.user) === 'POSH' && (
                  <div
                    className="pricing__card__button-active">
                    Active
                  </div>
                )}
              </div>
            )}
            {/* <button
              onClick={handleClickSubscribeSuccess}              
              className="neumorph__panel neumorph__surface-action__bump pricing__card__button-subscribe">
              Active
            </button> */}
            {/* <button
              onClick={handleClickSubscribeCancel}              
              className="neumorph__panel pricing__card__button-subscribe">
              Subscription cancelled
            </button> */}
          </div>
        {/* )} */}
      </div>
      {/* {!props.isLoggedIn && (
        <Link to="/auth/login">
          <button
            className="neumorph__panel neumorph__surface-action__bump pricing__card__button-subscribe">
            Start now
          </button>
        </Link>
      )} */}
      <GeneralFooter></GeneralFooter>
    </div>
  )
}

// ============================================================================

// map some state actions and state reads into props
const mapDispatchToProps = (dispatch) => {
  return {
    getPaymentStripeSession: (subscriptionType) => dispatch(getPaymentStripeSession(subscriptionType)),
  };
};

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    user: state.user,
    isLoggedIn: state.isLoggedIn,
    checkoutLink: state.checkoutLink,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PagePricing)