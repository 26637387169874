import "./PageUpload.css"

import React from "react";

// custom stuff
import UploadFiles from "../../components/FileUploader/FileUploader.js";
import PageFeatures from "../../components/Layout/PageFeatures.js";
import PagePricing from "../../components/Layout/PagePricing.js";
import GeneralHeader from "../../components/Layout/GeneralHeader/GeneralHeader.js";
import GeneralFooter from "../../components/Layout/GeneralFooter/GeneralFooter.js";

// ============================================================================

export default (props) => (
  <div>
    <GeneralHeader />

    <div className="wacky-uploader-wrapper">
      <UploadFiles />

      <GeneralFooter />
    </div>
  </div>
)