import "./PageReportAbuse.css";

import React, { useState } from "react";
import { connect } from 'react-redux';
import { ContactService } from "../../services/serviceContact";

import Button from '@mui/material/Button';

// ============================================================================

const PageReportAbuse = (props) => {
  const [reportAbuseText, setReportAbuseText] = useState(props.locale.BORING___REPORT_ABUSE_TEXT)
  const [reportAbuseLink, setReportAbuseLink] = useState('')

  const handleReportAbuseLinkChange = (event) => {
    const link = event.target.value;
    setReportAbuseLink(link);
  }

  const handleReportAbuseTextChange = (event) => {
    const text = event.target.value;
    setReportAbuseText(text);
  }

  const handleReportAbuseSendClick = () => {
    ContactService.reportAbuse({
      linkAbuse: reportAbuseLink,
      textDetails: reportAbuseText
    });

    setReportAbuseLink('');
    setReportAbuseText('');
  }

  return (
    <div className="neumorph__panel panel-wide neumorph__surface-blank__pressed boring-page__default-block">

      {/* <label>link (or ID) of a file you would want to report:</label> */}
      {/* <input type="text"></input> */}
      {/* <label>reason for report:</label> */}
      {/* <textarea> */}
      {/* Delete a link transfer */}

      {/* It's not possible to delete a link transfer. By using the link transfer option with the free service, you leave virtually no personal data. That is why we can't verify whether you're the actual owner of these links - and can't migrate them as a consequence. */}

      {/* Keep in mind: */}
      {/* Your transfer will expire naturally after 7 days and then will no longer be available. */}
      {/* Our links are not indexable, meaning no one can search Google for your transfers. */}

      {/* For more information on this, check out this article: https://wetransfer.zendesk.com/hc/en-us/articles/210604103-Can-I-delete-a-link-transfer- */}
      {/* </textarea> */}
      {/* <button>REPORT</button> */}
      {/* * we will review your request within 48 hours and delete the forementioned asset if it violates our ToS. */}

      <div className="boring-page__default-title">
        {props.locale.BORING___REPORT_ABUSE}
      </div>
      <div className="report-abuse-page__form">
        <div className="report-abuse-page__text">
          <div className="report-abuse-page__label">
            Link:
          </div>
          <input
            type="text"
            value={reportAbuseLink}
            onChange={handleReportAbuseLinkChange}
          />

          <div className="report-abuse-page__label">
            Complaint:
          </div>
          <textarea
            value={reportAbuseText}
            onChange={handleReportAbuseTextChange}
          />
        </div>
        <Button
          variant="contained"

          className="neumorph__surface-action__flat"
          onClick={handleReportAbuseSendClick}
        >
          SEND
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};

export default connect(mapStateToProps)(PageReportAbuse)