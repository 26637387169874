import "./GeneralFooter.css";

import axios from 'axios';
import { getRequestOptsAuth, redirectCrossBrowser } from "../../../services/utils.js"

import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SettingsIcon from '@material-ui/icons/Settings';
import EmailIcon from '@material-ui/icons/Email';

import Delimiter from "../../Delimiter/Delimiter.js";

// ============================================================================

// Encapsulate the way you're making requests (makes it a lot easier to tweak globally)
const apiClient = axios.create({
    // baseURL: "http://localhost:8000", // localhost
    // proxy: {
    //   port: 8000
    // },
    headers: {
      "Content-type": "application/json"
    }
});

const GeneralFooter = (props) => {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
      // if not a hash link, scroll to top
      if (hash === '') {
        window.scrollTo(0, 0);
      }
      // else scroll to id
      else {
        setTimeout(() => {
            const id = hash.replace('#', '');
            const element = document.getElementById(id);
            console.log(id, element)
            if (element) {
                element.scrollIntoView();
            }
        }, 0);
      }
    }, [pathname, hash, key]); // do this on route change

    const handleFeatureRequest = async () => {
        const opts = getRequestOptsAuth();
        const res = await apiClient.post(`/api/v1/contact/feature-request`, {}, opts)
        const resBody = res.data;
        redirectCrossBrowser(resBody.data.redirectUrl);
    }

    return (
        <div>
            <div className="footer">
                <div className="footer__secondary-menus">
                    {props.format !== 'minified' && (
                        <div>
                            <div className="footer__secondary-menus__line-1">
                                {!props.isLoggedIn && (<a target="blank" href="https://wackyupload.feedbear.com/boards/feature-requests">Feature Requests</a>)}
                                {props.isLoggedIn && (<Link onClick={handleFeatureRequest}>Request a Feature / Report a Bug</Link>)}
                                <span className="footer__delimiter">|</span>
                                <Link to="/faq">FAQs</Link>
                            </div>
                            <Delimiter text="Legal Stuff" appendix={{ text: "TL;DR", url: "/legal" }} />
                        </div>
                    )}
                    <div className="footer__secondary-menus__line-2">
                        <a className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe" href="https://www.iubenda.com/terms-and-conditions/73359179" title="Terms and Conditions">ToS</a>
                        <span className="footer__delimiter-mini">|</span>
                        {/*<Link to="/privacy">{props.locale.BORING___PRIVACY}</Link>*/}<a className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe" href="https://www.iubenda.com/privacy-policy/73359179" title="Privacy Policy ">Privacy Policy</a>{/* |<span className="footer__secondary-item__icon" title="Update your advertising tracking preferences"><a href="#" className="iubenda-cs-preferences-link"><SettingsIcon style={{ fontSize: 13 }}></SettingsIcon></a></span> <a className="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe" href="https://www.iubenda.com/privacy-policy/73359179/cookie-policy" title="Cookie Policy ">Cookie Policy</a>*/}
                        <span className="footer__delimiter-mini">|</span>
                        <span className="footer__secondary-item__icon" title={props.locale.BORING___REPORT_ABUSE}><Link to="/report">Report Abuse</Link></span>
                        {/* <span className="footer__secondary-item__icon" title={props.locale.BORING___REPORT_ABUSE}><Link to="/report"><ReportProblemIcon style={{ fontSize: 16 }}></ReportProblemIcon></Link></span> */}
                        {/* <span className="footer__secondary-item__icon" title={props.locale.BORING___CONTACT}><Link to="/contact"><EmailIcon style={{ fontSize: 16 }}></EmailIcon></Link></span> */}
                    </div>

                    {/* className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " */}
                    
                </div>
                <div className="footer__summary-stats">
                    contact (EN/ES/DE/RU): <a href="mailto:support@wackyupload.com">support@wackyupload.com</a><br/>
                    {props.uploadedTotalGb} Gb {props.locale.STATS___UPLOADED} © {new Date().getFullYear()} <a target="blank" href="http://spacecow.ltd">{props.locale.COMPANY_NAME}</a>
                </div>
            </div>
        </div >
    )
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.isLoggedIn,
        uploadedTotalGb: state.uploadedTotalGb,
        locale: state.locale,
    };
};
  
export default connect(mapStateToProps)(GeneralFooter)