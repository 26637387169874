import "./PageLegal.css"
import React from "react";
import { connect } from 'react-redux';
import GeneralHeader from "../../components/Layout/GeneralHeader/GeneralHeader.js";
import GeneralFooter from "../../components/Layout/GeneralFooter/GeneralFooter.js";

// ============================================================================

const PageLegal = (props) => (
  // <div className="neumorph__panel panel-wide neumorph__surface-blank__pressed boring-page__default-block">
  <div>
    <GeneralHeader />

    <div className="panel-wide">
      <div className="boring-page__default-title">
        Simple Speak Introduction for ToS and Privacy Policy
      </div>

      <div className="boring-page__default-text">
        <div className="legal-tldr__paragraph">
          You're busy. We get it. So here's a summed up Terms of Service / Privacy Policy in a few simple sentences. They don't replace the official legal stuff, but they will give you an idea of what's in there. Any questions or concerns - please <a href="mailto:support@wackyupload.com">contact us</a>.
        </div>
        {/* <div className="legal-tldr__paragraph legal-tldr__paragraph-point">
          - You have to be 16 to use our tools.
        </div> */}
        <div className="legal-tldr__paragraph legal-tldr__paragraph-point">
          - No cookies. We do use localStorage in your browser to save just the amount of information needed to make Wacky Upload operational.
        </div>
        <div className="legal-tldr__paragraph legal-tldr__paragraph-point">
          - We do not sell your data. We do gather minimal summary statistics using <a href="https://usefathom.com/features">Fathom</a>. No Google Analytics or FB Pixel.
        </div>

        <div className="legal-tldr__paragraph">
          That being said, here is a bit of an "Elephant in the room": Wacky Upload is NOT a platform for piracy / pornography and gore / extremist material of any kind / malware / botnet C&C / anything related to crypto currencies / backups (yes, this includes your favourite home video collections and Wikipedia dumps) / CI build artifacts / doxxing / database dumps containing personal information / anything illegal under UK law.
        </div>

        <div className="legal-tldr__paragraph legal-tldr__paragraph-point">
          - Your content is yours - you own it, and you are responsible for it. We'll only do what's needed for the service to work properly. Unless you are doing something illegal or someone reports your upload links as abusive, in which case we can block your ip, block and/or delete all of your stuff and suspend your accounts <a href="https://www.google.com/search?q=indefinitely">in-de-fi-ni-te-ly</a>.
        </div>
        <div className="legal-tldr__paragraph legal-tldr__paragraph-point">
          - You use our service at your own risk. We are not liable for any damages if things go wrong.
        </div>
        <div className="legal-tldr__paragraph legal-tldr__paragraph-point">
          - We are still small and trying to figure it all out, so we may make changes to our terms, service and/or fees from time to time.
        </div>

        <div className="legal-tldr__paragraph">
          That's pretty much it.
        </div>
      </div>

      <GeneralFooter format="minified" />
    </div>
  </div>
)

const mapStateToProps = (state) => {
  return {
      locale: state.locale,
  };
};

export default connect(mapStateToProps)(PageLegal)