import "./FileDownloaderLog.css";

// core
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatSize } from "../../services/utils";

// ============================================================================

const DownloadFilesLog = (props) => {
  const [download, setDownload] = useState({ name: '', size: '', uploadHash: '', viewCount: 0, downloadCount: 0 });

  useEffect(() => {
    const { expiry, created_at, files, uploadHash, stats: { views, downloads } } = props.downloadDataStats;

    // TODO: fix for multiple files (any ...[0] occurance in the code should be fixed tbh)
    const { name, size } = files && files[0]
    const viewCount = views ? views.length : 0;
    const downloadCount = downloads ? downloads.length : 0;
    setDownload({ name, size, uploadHash, viewCount, downloadCount, createdAt: new Date(created_at).toUTCString(), expiry: new Date(new Date(created_at).getTime() + expiry).toUTCString() })
  }, [props.downloadDataStats])

  return (
    <Link to={`/u/${download.uploadHash}`}>
      <div className="download-access-log">
        <div className="download-access-log__title">{download.name}</div>
        <div className="download-access-log__entry">
          <span className="download-access-log__entry-value">{formatSize(download.size)}</span>, views: <span className="download-access-log__entry-value">{download.viewCount}</span>, downloads: <span className="download-access-log__entry-value">{download.downloadCount}</span>
        </div>
        <div className="download-access-log__entry">
          Uploaded: <span className="download-access-log__entry-value">{download.createdAt}</span>
        </div>
        <div className="download-access-log__entry">
          Expiring: <span className="download-access-log__entry-value">{download.expiry}</span>
        </div>
      </div >
    </Link>
  );
}

export default DownloadFilesLog