import axios from 'axios';
import { getRequestOptsAuth } from "./utils.js"
import {
  getMessageEncoding,

  keyImport,
  keyExport,
  utf8ArrayToStr,
  bytesToSring,
  stringToBytes,

  generateKeyClient,
  encryptMessage,
  decryptMessage,
  generateKeyServer
} from "./serviceE2E.js"

// ============================================================================

// Encapsulate the way you're making requests (makes it a lot easier to tweak globally)
const apiClient = axios.create({
    // baseURL: "http://localhost:8000", // localhost
    // proxy: {
    //   port: 8000
    // },
    headers: {
      "Content-type": "application/json"
    }
});

// ============================================================================

const UploadFilesService = {
  upload: async ({ files, settings }, onUploadProgress) => {
    let fileEncrypted = null
    const opts = getRequestOptsAuth();

    // TODO: fix multi-file upload
    const file = files[0];








    /*
    // TODO: CLIENT-SIDE ENCRYPTION

    // const keyServer = await generateKeyServer();
    // const keyClient = await generateKeyClient(keyServer);

    // HARDCODED FOR TESTING PURPOSES
    const keyServer = await keyImport(new Uint8Array([90, 48, 179, 251, 126, 52, 213, 130, 32, 217, 239, 39, 21, 25, 169, 194, 34, 199, 159, 189, 215, 183, 114, 32, 170, 127, 44, 144, 38, 101, 196, 247]))
    const keyClient = new Uint8Array([16, 25, 39, 30, 250, 162, 31, 202, 175, 119, 18, 121])

    console.log(keyServer)
    console.log('================================')
    console.log(keyClient)
    // const keyClientDecoded = utf8ArrayToStr(keyClient)
    // const keyClientDecoded = Buffer.from(keyClient).toString('base64')
    const keyClientDecoded = bytesToSring(keyClient)
    console.log(keyClientDecoded)
    // console.log(Buffer.from(keyClient).toString('base64'))
    // console.log(new Uint8Array(Buffer.from(keyClientDecoded,'base64')))
    console.log(stringToBytes(keyClientDecoded))
    console.log(new Uint8Array(stringToBytes(keyClientDecoded)))
    console.log('================================')

    const keyServerExported = await keyExport(keyServer, 'raw')
    console.log(keyServerExported)
    
    var reader = new FileReader();
    reader.onload = async function () {
      var arrayBuffer = reader.result;
      var bytes = new Uint8Array(arrayBuffer);    // byte array aka uint8
      const textEncrypted = await encryptMessage(bytes, keyClient, keyServer);
      console.log(textEncrypted)
      // let encryptedByteCharacters = new Uint8Array(textEncrypted, 0, 5);
      // 3. To blob (file extends blob)
      // var encryptedByteCharacters = cipher.output.getBytes();     // encryptedByteCharacters is similar to an ATOB(b64) output
      // var asB64 = forge.util.encode64(encryptedBytes); 
      // var encryptedByteCharacters = atob(asB64);
    
      // Convert to Blob object
      var blob = new Blob([textEncrypted]);
      console.log(blob)
      // data.append('file', blob, file.name);
      fileEncrypted = blob
    }
    await reader.readAsArrayBuffer(file);

    // return
    */





    

    const res = await apiClient.post("/api/v1/file-sharing/link-upload", { filename: file.name, filesize: file.size, settings }, opts);
    let formData = new FormData();
    // formData.append("Content-Type", file.type);
    Object.entries(res.data.upload_target.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append("file", file);






    // formData.append("file", fileEncrypted);







    // create a custom instance of axios to add interceptors
    // read: https://axios-http.com/docs/interceptors
    // TODO: define interceptors to RETRY upload if it failed
    const axiosInstance = axios.create();
    // axiosInstance.interceptors.request.use(function (data) {
    //   console.log('data', data)
    //   return data
    // }, function (error) {
    //   console.log('error', error)
    //   return Promise.reject(error);
    // });

    // TODO: refactor res2 to return something meaningful
    const res2 = await axiosInstance.post(res.data.upload_target.url, formData, { onUploadProgress });
    return res.data.upload_meta;
  },
  getFile: (uploadHash) => {
    return apiClient.get(`/api/v1/file-sharing/u/${uploadHash}`);
  },
  getFileDownloadLink: (uploadHash, password) => {
    return apiClient.post(`/api/v1/file-sharing/download-link/${uploadHash}`, { password });
  },
  getFiles: () => {
    return apiClient.get("/api/v1/file-sharing");
  }
}

export {
  UploadFilesService,
}