import "./GeneralHeader.css";

import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import AccountCircle from '@material-ui/icons/AccountCircle';
// import Menu from '@material-ui/icons/Menu';
import ExitToApp from '@material-ui/icons/ExitToApp';
import LockResetIcon from '@mui/icons-material/LockReset';
import GeneralLogoSmall from "../GeneralLogoSmall/GeneralLogoSmall.js";
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PaymentIcon from '@mui/icons-material/Payment';

// import UserSettings from "../../UserSettings/UserSettings.js";
import { setLanguage } from "../../../store/actions";
import { generateAvatarColorBg, generateAvatarColorText, generateAvatarInitials } from "../../../services/utils.js";

// ============================================================================

const GeneralHeader = (props) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    const [pathCurrent, setPathCurrent] = useState(null);

    useEffect(() => {
        const pathname = window.location.pathname;
        setPathCurrent(pathname)
    }, []);

    const handleClickMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    }

    const changeLanguage = (event) => {
        const languageKey = event.target.attributes.data.value;
        props.setLanguage(languageKey);
    }

    return (
        <div className="header">
            <div className="header__item__logo">
                <GeneralLogoSmall />
            </div>
            <div className="header__item-left header__item__subscription-status">
                <div>
                    {/* {props.isLoggedIn && ( */}
                    <Link to="/">
                        <div className={`header__item__top-menu ${pathCurrent === '/' ? 'header__item__top-menu__selected' : ''}`}>
                            Upload
                        </div>
                    </Link>
                    {/* )} */}
                    {props.isLoggedIn && (
                        <Link to="/uploads">
                            <div className={`header__item__top-menu ${pathCurrent === '/uploads' ? 'header__item__top-menu__selected' : ''}`}>
                                Your Files
                            </div>
                        </Link>
                    )}
                    {/* {!props.isLoggedIn && (
                        <div className={`header__item__top-menu ${pathCurrent === '/features' ? 'header__item__top-menu__selected' : ''}`}>
                            <Link to="/features">Features</Link>
                        </div>
                    )} */}
                    {!props.isLoggedIn && (
                        <Link to="/pricing">
                            <div className={`header__item__top-menu ${pathCurrent === '/pricing' ? 'header__item__top-menu__selected' : ''}`}>
                                Pricing
                            </div>
                        </Link>
                    )}
                </div>
                {/* {props.isLoggedIn && (
                    // TODO: update subscription tiers accordingly
                    <span className="header__login-status__text">Subscription tier: <span className="header__login-status__username">{props.subscription || 'no'}</span></span>
                )} */}
                {/* {!props.isLoggedIn && (
                    <span className="header__login-status__text">Have an account? <Link className="header__login-status__link" to="auth/login">Sign in</Link></span>
                    )} */}
            </div>
            <div className="header__item-left header__item__login-status">
                {/* {props.isLoggedIn && (
                    <span className="header__login-status__text">Logged in as <span className="header__login-status__username">{props.username}</span></span>
                )} */}
                {/* {!props.isLoggedIn && (
                    <span className="header__login-status__text">(or <Link className="header__login-status__link" to="auth/register">Sign up</Link> and create a new one)</span>
                    )} */}
            </div>

            <div className="header__item-right header__item__account">
                {props.isLoggedIn && (
                    <div>
                        {!isMenuOpen && (
                            <div className="header__item__menu-toggle" to="/auth/logout">
                                <Link to="/settings">
                                    <div className="generative-avatar" style={{ backgroundColor: generateAvatarColorBg(props.username, 25), color: generateAvatarColorText(props.username) }}>{generateAvatarInitials(props.username)}</div>
                                </Link>
                                {/* <div className="generative-avatar" onClick={handleClickMenuToggle} style={{ backgroundColor: generateAvatarColorBg(props.username, 25), color: generateAvatarColorText(props.username) }}>{generateAvatarInitials(props.username)}</div> */}
                            </div>
                        )}
                        {isMenuOpen && (
                            <div>
                                <div className="header__item__menu-wrapper" onClick={handleMenuClose}>
                                    <div className={`header__item__menu header__item__menu-${isMenuOpen ? 'open' : 'closed'}`}>
                                        <div className="header__login-status__text">Subscription tier: <span className="header__login-status__username">{props.subscription || 'no'}</span></div>
                                        <div className="header__login-status__text">Logged in as <span className="header__login-status__username">{props.username}</span></div>
                                        <Link to="/uploads">
                                            <div className="header__item__menu-item">
                                                <FormatListNumberedIcon className="header__item__menu-icon" style={{ fontSize: 16 }}></FormatListNumberedIcon> Uploads
                                            </div>
                                        </Link>
                                        <Link to="/billing">
                                            <div className="header__item__menu-item">
                                                <PaymentIcon className="header__item__menu-icon" style={{ fontSize: 16 }}></PaymentIcon> Billing
                                            </div>
                                        </Link>
                                        <Link to="/pricing">
                                            <div className="header__item__menu-item">
                                                <LoyaltyIcon className="header__item__menu-icon" style={{ fontSize: 16 }}></LoyaltyIcon> Subscriptions
                                            </div>
                                        </Link>
                                        <Link to="/auth/reset-password">
                                            <div className="header__item__menu-item">
                                                <LockResetIcon className="header__item__menu-icon" style={{ fontSize: 16 }}></LockResetIcon> Reset Password
                                            </div>
                                        </Link>
                                        <Link to="/auth/logout">
                                            <div className="header__item__menu-item">
                                                <ExitToApp className="header__item__menu-icon" style={{ fontSize: 16 }}></ExitToApp> Logout
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="header__item__menu-toggle" to="/auth/logout">
                                    <div className="generative-avatar" onClick={handleClickMenuToggle} style={{ backgroundColor: generateAvatarColorBg(props.username, 15), color: generateAvatarColorText(props.username) }}>{generateAvatarInitials(props.username)}</div>
                                    {/* <Menu onClick={handleClickMenuToggle} style={{ fontSize: 30 }}></Menu> */}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {!props.isLoggedIn && (
                    <div>
                        <div className="header__create-an-account">Sign In / Create an account <ArrowForwardIcon style={{ fontSize: 14 }}></ArrowForwardIcon></div>
                        <Link to="/auth/login">
                            <AccountCircle style={{ fontSize: 32 }}></AccountCircle>
                        </Link>
                    </div>
                )}
            </div>
            {/* TODO: implement */}
            {/* <div className={`header__item-right header__item__languages ${props.isLoggedIn ? 'header__item__languages-logged-in' : ''}`}>
                {props.localeOptions.filter(l => l !== props.localeCurrent).map(l => (
                    <span key={l} className="header__language-selection" onClick={changeLanguage} data={l}>{l.toUpperCase()}</span>
                ))}
            </div>
            <div className="header__item-right header__language-current">{props.localeCurrent.toUpperCase()}</div> */}
        </div>
    );
}
  
const mapStateToProps = (state) => {
    return {
        username: state.username,
        subscription: state.subscription, // TODO: refactor this state as it dublicates state.user.limits.id
        isLoggedIn: state.isLoggedIn,
        localeOptions: state.localeOptions,
        localeCurrent: state.localeCurrent,
    };
};
  
export default connect(mapStateToProps, { setLanguage })(GeneralHeader)