import "./PageSettings.css";

// core
import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

// import ProgressBar from "../ProgressBar/ProgressBar.js";
import BackButton from "../BackButton/BackButton.js";
import Delimiter from "../Delimiter/Delimiter.js";
import { generateAvatarColorBg, generateAvatarColorText, generateAvatarInitials } from "../../services/utils.js";

// custom stuff
import { setSubscription, getUser } from "../../store/actions";

// ============================================================================

// reset Capitalization of the text inside buttons
const CamelCaseButton = styled(Button)({
  textTransform: 'none',
  fontSize: 16,
  border: '1px solid',
  lineHeight: 1.5,
  borderColor: 'rgba(0,0,0,0.1)',
});

const PageSettings = (props) => {
  useEffect(() => {
    props.getUser();
  }, []);

  useEffect(() => {
    // console.log(props.user);
  }, [props]);

  const formatSpace = (user) => {
    if (!user || !user.limits) {
      return 0;
    }

    const gbDecimal = 1000 * 1000 * 1000;
    const spaceUsedGb = user.limits.limit_upload_gb - user.currentSpace / 1000000000;

    if (spaceUsedGb < 0.01) {
      return spaceUsedGb.toFixed(3);
    } else if (spaceUsedGb < 0.1) {
      return spaceUsedGb.toFixed(2);
    }

    return spaceUsedGb.toFixed(1)
  }

  const formatSpaceTotal = (user) => {
    if (!user || !user.limits) {
      return 0;
    }

    return user.limits.limit_upload_gb
  }

  const formatSubscription = (user) => {
    if (!user || !user.limits) {
      return 'No Plan';
    }

    return `${user.limits.id} Plan`;
  }
  
    return (
      <div className="mg20">
        <BackButton></BackButton>
        <div className="settings-area">
            <div className="settings-area__text">
            <div className="wacky-logo__simple">
                <div className="generative-avatar generative-avatar__settings" style={{ backgroundColor: generateAvatarColorBg(props.username, 25), color: generateAvatarColorText(props.username) }}>{generateAvatarInitials(props.username)}</div>
            </div>

            {/* Title */}

            <div className="wacky-settings__title">
              Settings
            </div>
            <div className="wacky-settings__secondary">
              <span className="user-value">{props.username}</span>
            </div>
            <div className="wacky-settings__tertiary">
              {/* TODO: implement limits and current rates */}
              {/* {formatSubscription(props.user)}, <span className="user-value">{formatSpace(props.user)}</span> / {formatSpaceTotal(props.user)}Gb used */}
              {formatSubscription(props.user)}, <span className="user-value">{formatSpaceTotal(props.user)} Gb</span>
            </div>
            {/* <ProgressBar progress={100*182.3/200}></ProgressBar> */}
            
          {/* Suplementary text */}

                    {/* === Main CTA === */}

                <div className="action__settings__wrapper">
                  <Link to="/auth/logout">
                    <CamelCaseButton
                      variant="text"
                      className="action__link__just-text action__login__extra"
                    >
                      Logout
                    </CamelCaseButton>
                  </Link>
                </div>

          <Delimiter text="Your Subscription" />

          {/* <div className="action__setting-disabled">
            IN DEVELOPMENT
          </div> */}

          <div className="action__setting">
            <Link to="/billing">
              Billing
            </Link>
          </div>
          <div className="action__setting">
            <Link to="/pricing">
              Change Your Subscription
            </Link>
          </div>

          <Delimiter text="Account" />

          {/* <div className="action__setting-disabled">
            <Link to="/auth/reset-password">
              Notifications
            </Link>
          </div> */}
          <div className="action__setting">
            <Link to="/auth/reset-password">
              Reset Password
            </Link>
          </div>
          {/* <div className="action__setting">
            <Link to="/auth/reset-password">
              Delete Account
            </Link>
          </div> */}
          {/* <div className="action__setting">
            <Link to="/auth/reset-password">
              Activity Log
            </Link>
          </div> */}
        </div>
      </div>
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    user: state.user,
    username: state.username,
  };
};
export default connect(mapStateToProps, { setSubscription, getUser })(PageSettings)