import "./FileUploader.css";

// core
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import QRCode from "qrcode.react";
import QrScanner from 'qr-scanner';
import QRCodeFast2 from 'qrcode'

// icons
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// custom stuff
import { copyTextToClipboard, computeFileNames, computeFileSizeFormatted, blobToBase64 } from "../../services/utils.js"
import ProgressBar from "../ProgressBar/ProgressBar.js";
import GeneralLogo from "../Layout/GeneralLogo/GeneralLogo.js";
import TransferSummary from '../TransferSummary/TransferSummary.js';
import UploadFilesSettings from "..//FileUploaderSettings/FileUploaderSettings.js";
import { UploadFilesService } from "../../services/serviceUpload.js";
import { setUploadPassword, setFilesSelected } from "../../store/actions";

// ============================================================================

const UploadFiles = (props) => {
  const cssClassDragnDrop = 'upload-area__drag-n-drop';
  const [cssModClassUploadArea, setCssModClassUploadArea] = useState('neumorph__panel neumorph__surface-blank__pressed upload-area upload-area__before-upload');
  const [isCopiedToClipboard, setIsCopiedToClipboard] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [fileUploadedShareLink, setFileUploadedShareLink] = useState(undefined);

  const [fileUploadStarted, setFileUploadStarted] = useState(false);
  const [fileUploadComplete, setFileUploadComplete] = useState(false);

  const [fileUploadSnowCrashQR, setFileUploadSnowCrashQR] = useState(null);

  const [isOpenUploadAdvancedSettings, setIsOpenUploadAdvancedSettings] = useState(false);

  const handleCopyToClipboard = (event) => {
    // stop event propagation
    event.preventDefault();
    event.stopPropagation();

    copyTextToClipboard(fileUploadedShareLink)

    // show the confirmation message and fade it out afterwards
    setIsCopiedToClipboard(true);

    // HACK: reimplement this setTimeout out
    setTimeout(function () {
      setIsCopiedToClipboard(false);
    }.bind(this), 2000)
  };

  const selectFiles = (files) => {
    props.setFilesSelected(files);
  }

  const handleSelectFiles = (event) => {
    selectFiles(event.target.files);
  }

  const upload = async () => {
    const medium = props.fileUploadSetting.medium;
    const securityType = props.fileUploadSetting.securityType;

    if (medium === 'internet') {
      setFileUploadStarted(true);
      setFileUploadProgress(0);

      setCssModClassUploadArea('neumorph__panel neumorph__surface-blank__pressed upload-area');

      UploadFilesService.upload({
        files: props.filesSelected,
        settings: props.fileUploadSetting
      }, async (event) => {
        // TODO: test resetting the password to null
        setUploadPassword(null);
        const fileUploadProgressCurrent = Math.round((100 * event.loaded) / event.total);
        setFileUploadProgress(fileUploadProgressCurrent);
      }).then(async (fileObject) => {
        setFileUploadComplete(true);
        setCssModClassUploadArea('neumorph__panel neumorph__surface-blank__pressed upload-area upload-area__uploaded');
        setFileUploadedShareLink(fileObject.url.short); // direct link: fileObject.url.direct
        setFileUploadProgress(0);
        props.setFilesSelected(undefined);
      }).catch(() => {
        setCssModClassUploadArea('neumorph__panel neumorph__surface-blank__pressed upload-area upload-area__uploaded');
        setFileUploadedShareLink(undefined);
        setFileUploadProgress(0);
        props.setFilesSelected(undefined);
      });
    } else if (medium === 'qr') {
      setFileUploadSnowCrashQR(null);
  
      const files = props.filesSelected;
      const chunkSize = 64; // 64 bytes
      const codes = 10;
      
      let timer = new Date().getTime();
      let chunkCounter = 0;
      let chunkStart = 0;
      
      // do {
      //   const qrCodes = [];
      //   for (let i = 0; i < codes; i += 1) {
      //     const file = files[0]
      //     if (chunkCounter * chunkSize <= file.size) {
      //       const chunkEnd = Math.min(chunkStart + chunkSize, file.size);
            
      //       const chunk = file.slice(chunkStart, chunkEnd);
      //       const chunkBase64 = await blobToBase64(chunk);
            
      //       const qr = new qrcode(-1, 'L');
      //       qr.addData(chunkBase64, null);
      //       qr.make();
      //       // qrCodes.push(chunkBase64);
      //       chunkStart += chunkSize
      //       chunkCounter++;
      //     }
      //   }
      //   setFileUploadSnowCrashQR(qrCodes);
      // } while (chunkCounter * chunkSize <= file.size)
      // console.log('ok', new Date().getTime() - timer)

      let totalSize = 0;
      for (let fileIndex = 0; fileIndex < files.length; fileIndex += 1) {
        totalSize += files[fileIndex].size;
      }

      do {
        const qrCodes = [];
        for (let fileIndex = 0; fileIndex < files.length; fileIndex += 1) {
          const file = files[fileIndex];
          for (let i = 0; i < codes; i += 1) {
            if (chunkCounter * chunkSize <= file.size) {
              const chunkEnd = Math.min(chunkStart + chunkSize, file.size);

              const chunk = file.slice(chunkStart, chunkEnd);
              const chunkBase64 = await blobToBase64(chunk);
              
              const qr = new qrcode2(-1, 'L');
              qr.addData(chunkBase64, null);
              qr.make();
              // if (chunkCounter === 0) { console.log('4', new Date().getTime() - timer) }
              qrCodes.push(chunkBase64);
              chunkStart += chunkSize
              chunkCounter++;
            }
          }
        }
        setFileUploadSnowCrashQR(qrCodes);
        console.log(qrCodes)
      } while (chunkCounter * chunkSize <= totalSize)
      console.log('ok', new Date().getTime() - timer)

      // do {
      //   const qrCodes = [];
      //   for (let i = 0; i < codes; i += 1) {
      //     if (chunkCounter * chunkSize <= file.size) {
      //       const chunkEnd = Math.min(chunkStart + chunkSize, file.size);

      //       const chunk = file.slice(chunkStart, chunkEnd);
      //       const chunkBase64 = await blobToBase64(chunk);

      //       await QRCodeFast2.toDataURL(chunkBase64)

      //       chunkStart += chunkSize
      //       chunkCounter++;
      //     }
      //   }
      //   setFileUploadSnowCrashQR(qrCodes);
      // } while (chunkCounter * chunkSize <= file.size)
      // console.log('ok', new Date().getTime() - timer)

      // setFileUploadSnowCrashQR(null);
    }
  }

  const resetUpload = () => {
    setFileUploadStarted(false);
    setFileUploadComplete(false);
    setCssModClassUploadArea('neumorph__panel neumorph__surface-blank__pressed upload-area upload-area__before-upload');
    setFileUploadedShareLink(undefined);
    setFileUploadProgress(0);
    props.setFilesSelected(undefined);
  }

  const handleDragEnterOver = e => {
    if (!fileUploadStarted && !fileUploadedShareLink) {
      const clearCSS = cssModClassUploadArea.split(' ').filter(c => c !== cssClassDragnDrop)
      setCssModClassUploadArea([...clearCSS, cssClassDragnDrop].join(' '));
    }

    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = e => {
    if (!fileUploadStarted && !fileUploadedShareLink) {
      const clearCSS = cssModClassUploadArea.split(' ').filter(c => c !== cssClassDragnDrop)
      setCssModClassUploadArea(clearCSS.join(' '));
    }

    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = e => {
    if (!fileUploadStarted && !fileUploadedShareLink) {
      const clearCSS = cssModClassUploadArea.split(' ').filter(c => c !== cssClassDragnDrop)
      setCssModClassUploadArea(clearCSS.join(' '));

      selectFiles(e.dataTransfer.files)
    }

    e.preventDefault();
    e.stopPropagation();
  };

  // open advanced settings (no toggle, separate function for open and close)
  const openAdvancedSettings = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpenUploadAdvancedSettings(true);
  }

  // open advanced settings (no toggle, separate function for open and close)
  const closeAdvancedSettings = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpenUploadAdvancedSettings(false);
  }

  return (
    <div className="mg20 wacky-uploader">
      <GeneralLogo />
      <label
        htmlFor="btn-upload"
        onDrop={e => handleDrop(e)}
        onDragOver={e => handleDragEnterOver(e)}
        onDragEnter={e => handleDragEnterOver(e)}
        onDragLeave={e => handleDragLeave(e)}
      >
        <input
          id="btn-upload"
          name="btn-upload"
          style={{ display: 'none' }}
          type="file"
          multiple
          onChange={handleSelectFiles} />
        <div id="btn-upload__select" className={cssModClassUploadArea}>
          <div className="upload-area__text">
            {!fileUploadSnowCrashQR && !fileUploadedShareLink && (
              <div>
                {!props.filesSelected && !fileUploadStarted && (
                  <div>
                    <div className="upload-area__icon">
                      <CloudUploadOutlinedIcon style={{ fontSize: 80 }}></CloudUploadOutlinedIcon>
                    </div>
                    <div>{props.locale.UPLOAD___HELPER___LINE_1}</div>
                    {/* TODO: implement <a> tag */}
                    {/* <div>or <Button className="btn-choose" variant="outlined" component="span">browse</Button> files</div> */}
                    <div>{props.locale.UPLOAD___HELPER___LINE_2}</div>
                  </div>
                )}
                {props.filesSelected && (
                  <div>
                    <div className="upload-area__text__filename">{props.filesSelected && computeFileNames(props.filesSelected)}</div>
                    <div className="upload-area__text__filesize">{props.filesSelected && computeFileSizeFormatted(props.filesSelected)}</div>
                  </div>
                )}
                {(fileUploadStarted && !fileUploadComplete) && (
                  <div className="upload-link__progress">
                    <ProgressBar progress={fileUploadProgress}></ProgressBar>
                  </div>
                )}
              </div>
            )}
            {fileUploadSnowCrashQR && fileUploadSnowCrashQR.map(qr => (
              <div key={qr}>
                <div className="upload-link__qr-snow-crash">
                  <QRCode
                    style={{
                      width: '60px',
                      height: '60px',
                      background: 'white',
                      cursor: 'pointer',
                      float: 'left',
                    }}
                    renderAs="canvas"
                    // fgColor="hsl(0, 100%, 26%)"
                    fgColor="#9c008b" // TODO: import CSS variable here
                    size={150}
                    value={qr}/>
                </div>
              </div>
            ))}
            {!fileUploadSnowCrashQR && fileUploadedShareLink && (
              <div>
                <div className="upload-link__qr">
                  <QRCode
                    className="qr-code__code"
                    renderAs="svg"
                    size={150}
                    value={fileUploadedShareLink}/>
                </div>
                <div className="upload-link__instructions">
                  <div
                    className="upload-link__instructions__copy-to-clipboard"
                    onClick={handleCopyToClipboard}
                  >
                    {props.locale.UPLOAD___COMPLETE___COPY_TO_CLIPBOARD}
                  </div>

                  {isCopiedToClipboard && (
                    <div className="upload-link__instructions__copy-to-clipboard__confirmation">
                      {props.locale.UPLOAD___COMPLETE___COPIED_TO_CLIPBOARD}
                    </div>
                  )}

                  <div className="upload-link__instructions__text">
                    scan the QR code or share via link
                    {/* {props.locale.UPLOAD___COMPLETE___SHARE_VIA_LINK} */}
                  </div>
                  <div className="upload-link">
                    <a target="blank" href={fileUploadedShareLink}>{fileUploadedShareLink}</a>
                  </div>
                </div>
              </div>
            )}
          </div>
          <TransferSummary />
        </div>
      </label>

      {fileUploadedShareLink && (
        <div className="upload-new">
          {props.locale.UPLOAD___HELPER___REUPLOAD_1} <span className="upload-new__link" onClick={resetUpload}>{props.locale.UPLOAD___HELPER___REUPLOAD_2}</span>
        </div>
      )}

      {!fileUploadComplete && (
        <div>
          <div className="upload__confirmation">
            <div className="upload__confirmation__checkbox">
              *By clicking "UPLOAD" I agree with the <a href="https://www.iubenda.com/terms-and-conditions/73359179">Terms of Service</a> (last updated: 23.09.2022)
              {/* {props.locale.UPLOAD___DISLAIMER} */}
            </div>
            {!fileUploadStarted && !isOpenUploadAdvancedSettings && (
              <div className="upload__settings-advanced__toggle-open" onClick={openAdvancedSettings}>
                <KeyboardArrowDownIcon style={{ fontSize: 30 }}></KeyboardArrowDownIcon>
                <div className="upload__settings-advanced__toggle-text">{props.locale.ADVANCED_SETTINGS__BUTTON__OPEN}</div>
              </div>
            )}
            {!fileUploadStarted && isOpenUploadAdvancedSettings && (
              <div className="upload__settings-advanced__toggle-close" onClick={closeAdvancedSettings}>
                <KeyboardArrowUpIcon style={{ fontSize: 30 }}></KeyboardArrowUpIcon>
                <div className="upload__settings-advanced__toggle-text">{props.locale.ADVANCED_SETTINGS__BUTTON__CLOSE}</div>
              </div>
            )}
            <button
              className="neumorph__panel btn-upload neumorph__surface-action__bump"
              disabled={!props.filesSelected}
              onClick={upload}>
              {props.locale.UPLOAD___BUTTON}*
            </button>
            {!fileUploadStarted && isOpenUploadAdvancedSettings && !fileUploadedShareLink && (
              <div className={`upload__settings-advanced ${isOpenUploadAdvancedSettings ? 'upload__settings-advanced__opened' : ''}`}>
                <UploadFilesSettings />
              </div>
            )}
          </div>
        </div>
      )}
    </div >
  );
}

// ============================================================================

// map some state actions and state reads into props
const mapDispatchToProps = (dispatch) => {
  return {
    setFilesSelected: (file) => dispatch(setFilesSelected(file)),
  };
};

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    filesSelected: state.filesSelected,
    fileUploadSetting: state.fileUploadSetting,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UploadFiles)