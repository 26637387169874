import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

// custom stuff
import { UploadFilesService } from "../../services/serviceUpload.js";
import DownloadFiles from "../../components/FileDownloader/FileDownloader.js";
import GeneralHeader from "../../components/Layout/GeneralHeader/GeneralHeader.js";
import GeneralFooter from "../../components/Layout/GeneralFooter/GeneralFooter.js";

// ============================================================================

export default function PageDownload(props) {
  let params = useParams();

  // TODO: remove all inactive shortlinks!
  // const uploadHash = props.match.params.uploadHash;
  const uploadHash = params.uploadHash;
  const [downloadData, setDownloadData] = useState({});

  useEffect(() => {
    UploadFilesService.getFile(uploadHash).then((response) => {
      setDownloadData(response.data);
    });
  }, [uploadHash]);

  return (
    <div>
      {/* <GeneralHeader /> */}

      <div className="wacky-uploader-wrapper">
        {!!uploadHash && (
          <DownloadFiles downloadData={downloadData} />
        )}

        <GeneralFooter format="minified" />
      </div>
    </div>
  )
}