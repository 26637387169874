import "./FileDownloader.css";

// core
import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';

// icons
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { pingTransferStat } from "../../store/actions";
import { computeFileSizeFormatted, saveFileAs } from "../../services/utils.js"
import TransferSummary from '../TransferSummary/TransferSummary.js';
import FileDownloaderLog from '../FileDownloaderLog/FileDownloaderLog.js';
import GeneralLogo from "../Layout/GeneralLogo/GeneralLogo.js";
import axios from 'axios';

// TODO: expose this duplicate code around password into a separate component
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { utilValidatePassword } from "../AuthLogin/validationAuth.js";
import { UploadFilesService } from "../../services/serviceUpload.js";
import {
  getMessageEncoding,

  keyImport,
  keyExport,
  utf8ArrayToStr,
  bytesToSring,
  stringToBytes,

  generateKeyClient,
  encryptMessage,
  decryptMessage,
  decryptMessageInPlace,
  generateKeyServer,

  saveFile,
} from "../../services/serviceE2E.js"

// ============================================================================

const DownloadFiles = (props) => {
  // https://stackoverflow.com/questions/54545754/nodejs-react-download-file-from-s3-bucket-using-pre-signed-url
  const downloadFileRef = useRef(null);
  const [downloadFileUrl, setDownloadFileUrl] = useState();
  const [downloadFileName, setDownloadFileName] = useState();

  const [downloadDataFilename, setDownloadDataFilename] = useState('');
  const [downloadDataFilesize, setDownloadDataFilesize] = useState(0);
  const [downloadDataStats, setDownloadDataStats] = useState({});
  const [isPasswordRequired, setIsPasswordRequired] = useState(false);
  const [uploadHash, setUploadHash] = useState('');

  // TODO: expose this duplicate code around password into a separate component

  // password
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [validationPassword, setValidationPassword] = useState(true)
  const [validationPasswordMessage, setValidationPasswordMessage] = useState('')

  const handleChangePassword = (event) => {
    const passwordNew = event.target.value;
    validatePassword(passwordNew)
    setPassword(passwordNew);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const validatePassword = (password) => {
    setValidationPassword(true)
    setValidationPasswordMessage(null);

    if (password.length > 0) {
      const { success, messageUser } = utilValidatePassword(password)
      setValidationPassword(success)
      setValidationPasswordMessage(messageUser)
    }
  }

  useEffect(() => {
    const d = props.downloadData;

    if (d) {
      console.log(d)
      if ((uploadHash !== d.uploadHash) && (d.uploadHash !== undefined)) {
        setUploadHash(d.uploadHash);
        props.pingTransferStat(d.uploadHash, 'view')
      }

      if (d.files) {
        // TODO: refactor this bit for multiple files
        const file = d.files[0]
        setDownloadDataFilename(file.name);
        setDownloadDataFilesize(file.size);
      }

      setIsPasswordRequired(d.password);

      if (d.stats) {
        setDownloadDataStats(d.stats);
      }
    }
  }, [props.downloadData]);

  const handleDownloadClick = async () => {
    // ping api/v1/file-sharing/download-link/:uploadHash to get a download link
    try {
      const res = await UploadFilesService.getFileDownloadLink(uploadHash, password);
      const downloadUrl = res && res.data && res.data.url_download;

      if (downloadUrl) {
        setValidationPassword(true);
        setValidationPasswordMessage('');

        props.pingTransferStat(uploadHash, 'download')
        // saveFileAs(downloadUrl, downloadDataFilename);







/*
        
        // const response = await fetch(downloadUrl);

        // HARDCODED FOR TESTING PURPOSES
        const keyServer = await keyImport(new Uint8Array([90, 48, 179, 251, 126, 52, 213, 130, 32, 217, 239, 39, 21, 25, 169, 194, 34, 199, 159, 189, 215, 183, 114, 32, 170, 127, 44, 144, 38, 101, 196, 247]))
        const keyClient = new Uint8Array([16, 25, 39, 30, 250, 162, 31, 202, 175, 119, 18, 121])

        const response = await fetch('/data.txt');
        // const response = await fetch('/data.csv');
        const streamReader = response.body.getReader();
        console.log(streamReader)

        function decode(data: Uint8Array): void {
          decryptMessageInPlace(data, keyClient, keyServer);
        }
          
        streamReader.read().then(async function processData({ done, value }) {
          // Result objects contain two properties:
          // done  - true if the stream has already given you all its data.
          // value - some data. Always undefined when done is true.
          if (done) {
            console.log("Stream complete");      
            return;
          }
          
          console.log("value");
          
          // decode(value);
          const d = await decryptMessageInPlace(value, keyClient, keyServer)

          // HACK: fix for names with many dots in it
          const fileExtention = downloadDataFilename.split('.').pop()
          console.log(d)
          saveFile(d, downloadDataFilename, fileExtention)
          // console.log(bytesToSring(value))
          // Read some more, and call this function again
          return streamReader.read().then(value);
        });

        return
*/

        setDownloadFileUrl(downloadUrl);
        setDownloadFileName(downloadDataFilename);
        downloadFileRef.current?.click();
      } else {
        setValidationPassword(false);
        setValidationPasswordMessage('something went wrong');
      }
    } catch (e) {
      // TODO: refactor this shit
      setValidationPassword(false);
      setValidationPasswordMessage('incorrect password');
    }
  }

  return (
    <div className="mg20 wacky-uploader">
      <GeneralLogo />
      <label htmlFor="btn-download">
        <div className="neumorph__panel download-area">
          {(!!downloadDataFilesize) && (
            <div className="download-area__stats">
              {props.locale.VIEWS}: {downloadDataStats.views.length}, {props.locale.DOWNLOADS}: {downloadDataStats.downloads.length}
            </div>
          )}
          <div className="download-area__text">
            <div>
              {/* TODO: reimplement, it's ugly */}
              {(!downloadDataFilesize) && (
                <AccessTimeIcon style={{ paddingTop: '23px', fontSize: 80 }}></AccessTimeIcon>
              )}
              {(!!downloadDataFilesize) && (
                <CloudDownloadOutlinedIcon style={{ fontSize: 80 }}></CloudDownloadOutlinedIcon>
              )}

              {(!downloadDataFilesize) && (
                <div style={{ paddingTop: '13px' }}>
                  The link has probably expired.
                </div>
              )}

              <div>{downloadDataFilename}</div>
              {(!!downloadDataFilesize) && (
                <div style={{ paddingTop: '3px', fontSize: 10 }}>{computeFileSizeFormatted([{ size: downloadDataFilesize }])}</div>
              )}
            </div>
          </div>
          {(!!downloadDataFilesize) && (
            <TransferSummary transferSet={true} />
          )}
        </div>
      </label>

      {(isPasswordRequired) && (
        <div className="download-area__password">
          <div>
            <div>
              <div className="button-login__password-show-toggle">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {!showPassword && (
                    <Visibility
                      className="icon-login icon-login__password-show-toggle-on"
                      aria-label="toggle password visibility"
                    ></Visibility>
                  )}
                  {!!showPassword && (
                    <VisibilityOff
                      className="icon-login icon-login__password-show-toggle-off"
                      aria-label="toggle password visibility"
                    ></VisibilityOff>
                  )}
                </IconButton>
              </div>
              <div className="data__login__password-wrapper">
                <TextField
                  error={!validationPassword}
                  helperText={validationPasswordMessage}

                  fullWidth
                  placeholder="Enter your password for this upload"

                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handleChangePassword}
                  variant="outlined"
                  margin="dense"
                  color="secondary"

                  className="data__login__password"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {(!!downloadDataFilesize) && (
        <div className="download__confirmation">
          <label className="download__confirmation__checkbox">
            {props.locale.DOWNLOAD___DISCLAIMER}
          </label>
          {/* // TODO: fix the button (doesn't work in Firefox Focus on smartphones) */}
          <button
            className="neumorph__panel btn-download neumorph__surface-action__bump"
            onClick={handleDownloadClick}>
            {props.locale.DOWNLOAD___BUTTON}
          </button>
        </div>
      )}
      {/* <FileDownloaderLog downloadDataStats={downloadDataStats}/> */}


      <a
        href={downloadFileUrl}
        download={downloadFileName}
        className="hidden"
        ref={downloadFileRef}
      />
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};
export default connect(mapStateToProps, { pingTransferStat })(DownloadFiles)