import axios from 'axios';
import { getRequestOptsAuth } from "./utils.js"

// ============================================================================

// Encapsulate the way you're making requests (makes it a lot easier to tweak globally)
const apiClient = axios.create({
    // baseURL: "http://localhost:8000", // localhost
    // proxy: {
    //   port: 8000
    // },
    headers: {
      "Content-type": "application/json"
    }
});

// ============================================================================

const HTTP_ERROR_CODES__CONSUMABLE = [400, 401, 403, 429]; // 4xx error codes that our API describes

const AuthService = {
  updateLoginToken: ({ token }) => {
    localStorage.setItem("token", `Bearer ${token}`);
  },

  login: async ({ email, password }) => {
    // send a login attempt
    // login (server-side)
    try {
      const res = await apiClient.post("/api/v1/auth/login", { email, password });
      // server was able to handle the request
      const resBody = res && res.data;
      const data = resBody && resBody.data; // TODO: data.data -> improve field names

      // login (client-side)
      if (resBody.success && !!data.token && data.nextStep === 'login') {
        // actually store it in the localstore
        localStorage.setItem("username", email);
        localStorage.setItem("token", `Bearer ${data.token}`);
      }

      return res.data;
    } catch (error) {
      if ((HTTP_ERROR_CODES__CONSUMABLE.includes(error.response.status)) && error.response.data) {
        return error.response.data;
      } else {
        return null;
      }
    }
  },
  emailSend: async ({ email, loginType }) => {
    // send an email confirmation email
    try {
      let type = null;
      if (loginType === 'forgot-password') { type = 'forgot-password' }
      if (loginType === 'confirm-email') { type = 'confirm-email' }

      const res = await apiClient.post("/api/v1/auth/email-send", { email, type });
      return res.data;
    } catch (error) {
      if ((HTTP_ERROR_CODES__CONSUMABLE.includes(error.response.status)) && error.response.data) {
        return error.response.data;
      } else {
        return null;
      }
    }
  },
  resetPassword: async ({ password, token }) => {
    try {
      const opts = getRequestOptsAuth();
      const res = await apiClient.post("/api/v1/auth/reset-password", { password, token }, opts);
      return res.data;
    } catch (error) {
      if ((HTTP_ERROR_CODES__CONSUMABLE.includes(error.response.status)) && error.response.data) {
        return error.response.data;
      } else {
        return null;
      }
    }
  },
  logout: async () => {
    try {
      // logout (server-side)
      const opts = getRequestOptsAuth();
      const res = await apiClient.post(`/api/v1/auth/logout`, {}, opts)
      // logout (client-side)
      const resBody = res && res.data;
      if (resBody.success) {
        localStorage.removeItem("username");
        localStorage.removeItem("token");
      }

      return res.data;
    } catch (error) {
      if ((HTTP_ERROR_CODES__CONSUMABLE.includes(error.response.status)) && error.response.data) {
        return error.response.data;
      } else {
        return null;
      }
    }
  },
  register: async ({ username, email, password }) => {
    try {
      const res = await apiClient.post("/api/v1/auth/register", { username, email, password });
      return res.data;
    } catch (error) {
      if ((HTTP_ERROR_CODES__CONSUMABLE.includes(error.response.status)) && error.response.data) {
        return error.response.data;
      } else {
        return null;
      }
    }
  },
  registerConfirmEmail: async ({ token }) => {
    try {
      const res = await apiClient.post("/api/v1/auth/register-confirm-email", { token });
      return res.data;
    } catch (error) {
      // TODO: refactor this dublicate code everywhere in this file
      if ((HTTP_ERROR_CODES__CONSUMABLE.includes(error.response.status)) && error.response.data) {
        return error.response.data;
      } else {
        return null;
      }
    }
  },

  // TODO: implement the 'REMEMBER ME' button and then a mechanism for renewing tokens as such
  loginRenewToken: async ({ email, token }) => {
    const opts = getRequestOptsAuth();
    const res = await apiClient.post("/api/v1/auth/login-renew-token", { email, token }, opts);
    return res.data;
  },
}

export {
  AuthService,
}