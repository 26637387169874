import "./AuthLogin.css";

// core
import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import GeneralLogoSmall from "../Layout/GeneralLogoSmall/GeneralLogoSmall.js";
import BackButton from "../BackButton/BackButton.js";

// custom stuff
import { setLogin } from "../../store/actions";
import { AuthService } from "../../services/serviceAuth.js";
import { } from "../../services/serviceE2E.js";
import { utilValidateUsername, utilValidateEmail, utilValidatePassword, utilValidateResetToken, utilValidateConfirmEmailToken } from "./validationAuth.js"
import { getRequestOptsAuth, redirectCrossBrowser } from "../../services/utils.js"

// ============================================================================

// reset Capitalization of the text inside buttons
const CamelCaseButton = styled(Button)({
  textTransform: 'none',
  fontSize: 16,
  border: '1px solid',
  lineHeight: 1.5,
  borderColor: 'rgba(0,0,0,0.1)',
});

const AuthLogin = (props) => {
  let history = useNavigate();

  const timerResendEmail = useRef();
  const timerResendEmailDelay = 20; // seconds
  const [disableResendButton, setDisableResendButton] = useState(false);
  const [disableResendButtonCounter, setDisableResendButtonCounter] = useState(0);
  
  const [loginType, setLoginType] = useState('');
  const [registerHash, setRegisterHash] = useState('');
  const [resetHash, setResetHash] = useState('');

  const [serverSuccessMessage, setServerSuccessMessage] = useState('');
  const [serverErrorMessage, setServerErrorMessage] = useState('');

  // auth tokens
  const [tokenAuth, setTokenAuth] = useState('');
  const [tokenAuthEmailConfirm, setTokenAuthEmailConfirm] = useState('');
  const [tokenForgotPassword, setTokenForgotPassword] = useState('');

  // user nickname
  const [username, setUsername] = useState('')
  const [validationUsername, setValidationUsername] = useState(true)
  const [validationUsernameMessage, setValidationUsernameMessage] = useState('')

  // user email
  const [email, setEmail] = useState('')
  const [validationEmail, setValidationEmail] = useState(true)
  const [validationEmailMessage, setValidationEmailMessage] = useState('')

  const [emailDuplicate, setEmailDuplicate] = useState('')
  const [validationEmailDuplicate, setValidationEmailDuplicate] = useState(true)
  const [validationEmailDuplicateMessage, setValidationEmailDuplicateMessage] = useState('')

  // password
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [validationPassword, setValidationPassword] = useState(true)
  const [validationPasswordMessage, setValidationPasswordMessage] = useState('')

  useEffect(async () => {
    if (loginType !== props.loginType) {
      setServerErrorMessage(null);
      if (props.loginType === 'login') { // mainly for the switch from register to login
        resetInputs();
      }
      if (props.loginType === 'register') { // switch from login to register
        resetInputs();
      }

      setLoginType(props.loginType)
      setRegisterHash(props.registerHash)
      setResetHash(props.resetHash)

      if (props.loginType === 'logout') {
        logout()
      }

      if (props.loginType === 'register' && !!props.registerHash) {
        setTokenAuthEmailConfirm(props.registerHash)

        const validateConfirmEmailToken = utilValidateConfirmEmailToken(props.registerHash);
        if (!validateConfirmEmailToken.success) {
          history('/auth/error');
        }
  
        const { success, messageDev, messageUser } = await AuthService.registerConfirmEmail({ token: props.registerHash });
        if (success) {
          history("/auth/confirm-email-success");
        } else {
          if (messageDev === 'invalid session') { // TODO: replace those string comparisons with response ID's that don't change
            history("/auth/confirm-email-session-expired");
          } else if (messageDev === 'email could not be sent') {
            // well, too bad but let's not ask the user to do anything with that :(
            // behave like a champ: don't flinch and pretend that error never happened
            history("/auth/confirm-email-success");
          } else {
            history('/auth/error');
          }
        }
      }

      if (props.loginType === 'reset-password' && !!props.resetHash) {
        setTokenForgotPassword(props.resetHash)
      }
    }
  }, [props.loginType, props.registerHash, props.resetHash])

  useEffect(() => {
    if (loginType !== props.loginType) {
      if (loginType === 'logout') {
        logout()
      }
    }
  }, [props.loginType])

  // === CTA ===

  const logout = async () => {
    const opts = getRequestOptsAuth()
    if (!opts) {
      // we were already logged out;
      history("/");
    } else {
      const resData = await AuthService.logout();

      // server hit a 500 (the definition of an anti-jackpot)
      if (!resData) {
        // it's ok, do not make the user worried, the token will expire automatically
        // setServerErrorMessage('Something went wrong')
      }

      // clear redux
      props.setLogin(null, null)

      if (!resData.success) {
        history("/");
      }
    }
  }

  // === reset input fields ===

  const resetUsername = () => {
    setUsername('');
  }
  const resetEmail = () => {
    setEmail('');
    setEmailDuplicate('');
  }
  const resetPassword = () => {
    setPassword('');
  }
  const resetServerResponse = () => {
    setServerErrorMessage(null);
    setServerSuccessMessage(null);
  }
  const resetInputs = () => {
    resetUsername();
    resetEmail();
    resetPassword();
  }

  // === handle user input ===

  // TODO: allow login with username
  const handleSigninClick = async () => {
    resetServerResponse();

    // validation assumes we have a non-zero email (to not show errors on start or empty fields)
    // so let's check that claim
    if (!!email && !!password) {
      // support HTTP codes 200 (ok), 400 (validation errors), 401 (unauthorized), 403 (forbidden), 429 (hitting rate-limits), 500 (chaos)
      const res = await AuthService.login({ email, password });
      if (res) {
        // server was able to handle the request
        if (res.success && res.data.token && res.data.nextStep === 'login') {
          // update redux
          props.setLogin(email, res.data.token);
          resetInputs();
          resetServerResponse();
          history("/");
        } else if (res.success && res.data.nextStep === 'email_confirmation') {
          // do not reset login details (= don't use history) because we will need to pass an email to be able to send a copy of an account confirmation email 
          resetServerResponse();
          setLoginType("confirm-email");
        } else {
          // show error message
          setServerErrorMessage(res.messageUser);
        }
      } else {
        setServerErrorMessage('Something went wrong');
      }
    } else {
      if (!email) {
        setValidationEmail(false);
        setValidationEmailMessage('Please enter your email'); // TODO: i18n all such strings
      }
      if (!password) {
        setValidationPassword(false);
        setValidationPasswordMessage('Please create a password');
      }
    }
  }

  const handleEmailSendClick = async () => {
    resetServerResponse();

    // validation assumes we have a non-zero email (to not show errors on start or empty fields)
    // so let's check that claim
    if (!!email) {
      // Debounce sending emails like mad (you'll hit the rate limiter anyways)
      setDisableResendButton(true);
      if (!timerResendEmail.current) {
        let delaySeconds = timerResendEmailDelay;
        setDisableResendButtonCounter(delaySeconds);

        timerResendEmail.current = setInterval(function () {
          delaySeconds--
          setDisableResendButtonCounter(delaySeconds);
          if (delaySeconds === 0) {
            setDisableResendButton(false);
            clearInterval(timerResendEmail.current);
            timerResendEmail.current = null
          }
        }, 1000);
      }

      const res = await AuthService.emailSend({ email, loginType });

      if (res) {
        // server was able to handle the request
        if (res.success) {
          if (loginType === 'confirm-email') {
            resetInputs(); // TODO: ok, this case is very non-obvious so please refactor all the logic into a nice state machine at some point
          }
          setServerSuccessMessage(res.messageUser);
        } else {
          // show error message
          setServerErrorMessage(res.messageUser);
        }
      } else {
        setServerErrorMessage('Something went wrong');
      }
    } else {
      if (!email) {
        setValidationEmail(false);
        setValidationEmailMessage('Please enter your email'); // TODO: i18n all such strings
      }
    }
  }

  const handleResetPasswordClick = async () => {
    resetServerResponse();

    // validation assumes we have a non-zero email (to not show errors on start or empty fields)
    // so let's check that claim
    if (!!password) {
      const token = tokenForgotPassword;
      const validationTokenForgotPassword = utilValidateResetToken(token);
      if (!validationTokenForgotPassword.success) {
        history('/')
      }

      const res = await AuthService.resetPassword({ password, token });
      if (res) {
        // server was able to handle the request
        if (res.success) {
          const newLoginToken = res.data.token;
          if (newLoginToken) {
            // store new token in localstore and redux
            await AuthService.updateLoginToken({ token: newLoginToken });
            props.setLogin(email, newLoginToken)
            // TODO: add a loader spinner to avoid page flickering on redirect
            history('/auth/reset-password-success');
          } else {
            // show a success message
            history('/auth/forgot-password-success');
          }
        } else {
          // show error message
          if (res.messageDev === 'invalid session') {
            history('/auth/forgot-password-session-expired');
          } else {
            setServerErrorMessage(res.messageUser);
          }
        }
      } else {
        setServerErrorMessage('Something went wrong');
      }
    } else {
      setValidationPassword(false);
      setValidationPasswordMessage('Please create a password');
    }
  }

  const handleSignupSendClick = async () => {
    resetServerResponse();

    // validation assumes we have a non-zero email (to not show errors on start or empty fields)
    // so let's check that claim
    if (!!username && !!email && !!password) {
      const res = await AuthService.register({ username, email, password });
      if (res.success) {
        // No history here because we do not want to save the history under the back button and somehow history resets email input
        // history("/auth/confirm-email");
        // redirectCrossBrowser('/auth/confirm-email');
        setLoginType('confirm-email');
      } else {
        if (res.messageDev === 'email could not be sent') {
          // do not reset login details (= don't use history) because we will need to pass an email to be able to send a copy of an account confirmation email 
          setLoginType('confirm-email');
        } else if (res.messageDev === 'username taken') {
          setValidationUsername(false);
          setValidationUsernameMessage(res.messageUser);
        } else if (res.messageDev === 'email taken') {
          setValidationEmail(false);
          setValidationEmailMessage(res.messageUser);            
        } else {
          setServerErrorMessage(res.messageUser);
        }
      }
    } else {
      if (!username) {
        setValidationUsername(false);
        setValidationUsernameMessage('Please create a username');
      }
      if (!email) {
        setValidationEmail(false);
        setValidationEmailMessage('Please enter your email');
      }
      if (!password) {
        setValidationPassword(false);
        setValidationPasswordMessage('Please create a password');
      }
    }
  }

  const handleRefreshTokenClick = () => {
    const token = tokenAuth;

    AuthService.loginRenewToken({ email, token });
  }

  const handleChangeUsername = (event) => {
    const usernameNew = event.target.value;
    validateUsername(usernameNew)
    setUsername(usernameNew);
  }

  const handleChangeEmail = (event) => {
    const emailNew = event.target.value;
    validateEmail(emailNew)
    setEmail(emailNew);
  }

  const handleChangeEmailDuplicate = (event) => {
    const emailDuplicate = event.target.value;
    validateEmailDuplicate(email, emailDuplicate)
    setEmailDuplicate(emailDuplicate);
  }

  const handleChangePassword = (event) => {
    const passwordNew = event.target.value;
    validatePassword(passwordNew)
    setPassword(passwordNew);
  }

  // === Validation ===
  // step 1/3: validate user input assuming they put something
  // step 2/3: validate if user input was complete (on CTA button clicks)
  // step 3/3: server-side validation
  
  const validateUsername = (username) => {
    setValidationUsername(true)
    setValidationUsernameMessage(null)

    if (username.length > 0) {
      const { success, messageUser } = utilValidateUsername(username)
      setValidationUsername(success)
      setValidationUsernameMessage(messageUser)
    }
  }

  const validateEmail = (email) => {
    // if user already typed the validation email, revalidate
    if (emailDuplicate) {
      validateEmailDuplicate(email, emailDuplicate);
    }

    setValidationEmail(true)
    setValidationEmailMessage(null)

    if (email.length > 0) {
      const { success, messageUser } = utilValidateEmail(email)
      setValidationEmail(success)
      setValidationEmailMessage(messageUser)
    }
  }

  const validateEmailDuplicate = (email, emailDuplicate) => {
    const isSameEmail = email === emailDuplicate;
    setValidationEmailDuplicate(isSameEmail);
    setValidationEmailDuplicateMessage(isSameEmail ? '' : 'emails do not match')
  }

  const validatePassword = (password) => {
    setValidationPassword(true)
    setValidationPasswordMessage(null);

    if (password.length > 0) {
      const { success, messageUser } = utilValidatePassword(password)
      setValidationPassword(success)
      setValidationPasswordMessage(messageUser)
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div className="mg20">
      <div className="login-area">
        <div className="login-area__text">
          <div className="wacky-logo__simple">
            {!registerHash && (
              <GeneralLogoSmall width="96" height="96" />
            )}
          </div>

          {!resetHash && ((loginType === 'reset-password') || (loginType === 'reset-password-success')) && (
            <BackButton></BackButton>
          )}


          {/* Title */}

          <div className="wacky-login__title">
            {loginType === 'login' && (
              <span>Sign in</span>
            )}
            {(loginType === 'register' && !registerHash) && (
              <span>Create an account</span>
            )}
            {((loginType === 'confirm-email') || (loginType === 'confirm-email-success')) && (
              <span>Email confirmation</span>
            )}
            {((loginType === 'forgot-password') || (loginType === 'forgot-password-success')) && (
              <span>Forgot password</span>
            )}
            {((loginType === 'reset-password') || (loginType === 'reset-password-success')) && (
              <span>Reset password</span>
            )}
            {loginType === 'logout' && (
              <span>Logout</span>
            )}
            {loginType === 'error' && (
              <span>Error</span>
            )}
            {((loginType === 'confirm-email-session-expired') || (loginType === 'forgot-password-session-expired') || (loginType === 'session-expired')) && (
              <span>Session expired</span>
            )}
          </div>

          {/* Suplementary text */}

          {loginType === 'logout' && (
            <div className="login-area__text-subsecondary">
              You are successfully logged out of Wacky.
            </div>
          )}

          {loginType === 'forgot-password' && (
            <div className="login-area__text-subsecondary">
              Type in your email address and we will send you a password reset link that will allow you to choose a new password.
            </div>
          )}
          {((loginType === 'forgot-password-success') || (loginType === 'reset-password-success')) && (
            <div className="login-area__text-subsecondary">
              Your password has been successfully changed.
            </div>
          )}
          {loginType === 'forgot-password-session-expired' && (
            <div className="login-area__text-subsecondary">
              Your forgot password link has expired. Please repeat the whole process again.
            </div>
          )}
          {loginType === 'confirm-email' && (
            <div className="login-area__text-subsecondary">
              We have sent you a confirmation email. Please check your inbox and follow the instructions in the email to activate your account.

              If you didn't get the email, please click "Resend email"
            </div>
          )}
          {loginType === 'confirm-email-success' && (
            <div className="login-area__text-subsecondary">
              Your account has been successfully activated! Enjoy your time with Wacky Upload :)
            </div>
          )}
          {loginType === 'confirm-email-session-expired' && (
            <div className="login-area__text-subsecondary">
              Your email confirmation link has expired. Please try to login with your password so that we could send you another confirmation email.
            </div>
          )}
          {loginType === 'session-expired' && (
            <div className="login-area__text-subsecondary">
              Your login session has expired. Please login again.
            </div>
          )}
          {loginType === 'error' && (
            <div className="login-area__text-subsecondary">
              Something terrible has happened. We are investigating the error. Sorry for any inconvenience caused.
            </div>
          )}

          {/* === Main CTA === */}

          <div>
            <label htmlFor="btn-login">
              {(loginType === 'register' && !registerHash) && (
                <div className="data__login__username-wrapper">
                  <TextField
                    error={!validationUsername}
                    helperText={validationUsernameMessage}
                    
                    fullWidth
                    placeholder="Enter your username"
                    
                    label="Username"
                    type="text"
                    value={username}
                    onChange={handleChangeUsername}
                    variant="outlined"
                    margin="dense"
                    color="secondary"

                    className="data__login__username"
                  />
                </div>
              )}
              {((loginType === 'login') || (loginType === 'register' && !registerHash) || (loginType === 'forgot-password')) && (
                <div className="data__login__email-wrapper">
                  <TextField
                    error={!validationEmail}
                    helperText={validationEmailMessage}
                    
                    fullWidth
                    placeholder="Enter a valid email"
                    
                    label="Email address"
                    type="text"
                    value={email}
                    onChange={handleChangeEmail}
                    variant="outlined"
                    margin="dense"
                    color="secondary"

                    className="data__login__email"
                  />
                </div>
              )}
              {(loginType === 'register' && !registerHash) && (
                <div className="data__login__email-wrapper">
                  <TextField
                    error={!validationEmailDuplicate}
                    helperText={validationEmailDuplicateMessage}
                    
                    fullWidth
                    placeholder="Repeat your email"
                    
                    label="Repeat your email address"
                    type="text"
                    value={emailDuplicate}
                    onChange={handleChangeEmailDuplicate}
                    variant="outlined"
                    margin="dense"
                    color="secondary"

                    className="data__login__email"
                  />
                </div>
              )}
              {((loginType === 'login') || (loginType === 'register' && !registerHash)) && (
                <div>
                  <div className="button-login__password-show-toggle">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword && (
                        <Visibility
                          className="icon-login icon-login__password-show-toggle-on"
                          aria-label="toggle password visibility"
                        ></Visibility>
                      )}
                      {!!showPassword && (
                        <VisibilityOff
                          className="icon-login icon-login__password-show-toggle-off"
                          aria-label="toggle password visibility"
                        ></VisibilityOff>
                      )}
                    </IconButton>
                  </div>
                  <div className="data__login__password-wrapper">
                    <TextField
                      error={!validationPassword}
                      helperText={validationPasswordMessage}

                      fullWidth
                      placeholder="Enter your password"

                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={handleChangePassword}
                      variant="outlined"
                      margin="dense"
                      color="secondary"

                      className="data__login__password"
                    />
                  </div>
                </div>
              )}

              {((loginType === 'reset-password')) && (
                <div>
                  <div className="button-login__password-show-toggle">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {!showPassword && (
                        <Visibility
                          className="icon-login icon-login__password-show-toggle-on"
                          aria-label="toggle password visibility"
                        ></Visibility>
                      )}
                      {!!showPassword && (
                        <VisibilityOff
                          className="icon-login icon-login__password-show-toggle-off"
                          aria-label="toggle password visibility"
                        ></VisibilityOff>
                      )}
                    </IconButton>
                  </div>
                  <div className="data__login__password-wrapper">
                    <TextField
                      error={!validationPassword}
                      helperText={validationPasswordMessage}

                      fullWidth
                      placeholder="Enter your new password"

                      label="New password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={handleChangePassword}
                      variant="outlined"
                      margin="dense"
                      color="secondary"

                      className="data__login__password"
                    />
                  </div>
                </div>
              )}

              {loginType === 'login' && (
                <div className="action__login__wrapper">
                  <CamelCaseButton
                    variant="contained"
                    disabled={!validationEmail || !validationPassword}

                    className="neumorph__surface-action__flat action__login__main"
                    onClick={handleSigninClick}
                  >
                    {props.locale.SIGNIN___BUTTON}
                  </CamelCaseButton>
                </div>
              )}
              {loginType === 'register' && !registerHash && (
                <div className="action__login__wrapper">
                  <CamelCaseButton
                    variant="contained"

                    // TODO: add !email || !emailDuplicate || !password reaction to this button saying "please fill the form"
                    disabled={!validationEmail || !validationEmailDuplicate || !validationPassword}

                    className="neumorph__surface-action__flat action__login__main"
                    onClick={handleSignupSendClick}
                  >
                    {props.locale.SIGNUP___BUTTON}
                  </CamelCaseButton>
                </div>
              )}
              {((loginType === 'confirm-email') || (loginType === 'forgot-password')) && (
                <div className="action__login__wrapper">
                  <CamelCaseButton
                    variant="contained"

                    // TODO: add !email reaction to this button saying "please fill the form"
                    disabled={!validationEmail || disableResendButton}

                    className="neumorph__surface-action__flat action__login__main"
                    onClick={handleEmailSendClick}
                  >
                    {disableResendButton && (
                      <span>You can resend in { disableResendButtonCounter }s...</span>
                    )}
                    {!disableResendButton && (loginType === 'confirm-email') && (
                      <span>Resend email</span>
                    )}
                    {!disableResendButton && (loginType === 'forgot-password') && (
                      <span>Email password reset link</span>
                    )}
                  </CamelCaseButton>
                </div>
              )}
              {loginType === 'reset-password' && (
                <div className="action__login__wrapper">
                  <CamelCaseButton
                    variant="contained"

                    // TODO: add !email reaction to this button saying "please fill the form"
                    disabled={!validationEmail}

                    className="neumorph__surface-action__flat action__login__main"
                    onClick={handleResetPasswordClick}
                  >
                    {props.locale.PASSWORD_FORGOT___BUTTON}
                  </CamelCaseButton>
                </div>
              )}
              {(
                (loginType === 'confirm-email-success') ||
                (loginType === 'confirm-email-session-expired') ||
                (loginType === 'session-expired') ||
                (loginType === 'forgot-password-success')) && (
                <div className="action__login__wrapper">
                  <Link to="/auth/login">
                    <CamelCaseButton
                      variant="contained"
                      className="neumorph__surface-action__flat action__login__main"
                    >
                      Sign In
                    </CamelCaseButton>
                  </Link>
                </div>
              )}
            </label>
          </div>

          {/* Server error */}

          {!!serverSuccessMessage && (
            <div className="login-area__text-success-server">{serverSuccessMessage}</div>
          )}
          {!!serverErrorMessage && (
            <div className="login-area__text-error-server">{serverErrorMessage}</div>
          )}

          {/* === Extra CTA === */}

          {loginType === 'login' && (
            <div>
              <Link to="/auth/forgot-password">
                <div className="login-area__text-subsecondary">Forgot your password?</div>
              </Link>
            </div>
          )}
          {loginType === 'forgot-password' && (
            <div>
              <Link to="/auth/login">
                <div className="login-area__text-subsecondary">Back to login</div>
              </Link>
            </div>
          )}

          {/* === Secondary CTA === */}

          {loginType === 'login' && (
            <div>
              <div className="login-area__text-secondary">Don't have a Wacky account?</div>
              <Link to="/auth/register">
                <CamelCaseButton
                  variant="text"
                  className="action__link__just-text action__login__extra"
                >
                  Create new account
                </CamelCaseButton>
              </Link>
            </div>
          )}
          {loginType === 'register' && !registerHash && (
            <div>
              <div className="login-area__text-secondary">Already have an account?</div>
              <Link to="/auth/login">
                <CamelCaseButton
                  variant="text"
                  className="action__link__just-text action__login__extra"
                >
                  Sign in
                </CamelCaseButton>
              </Link>
            </div>
          )}
          {((loginType === 'logout') || (loginType === 'reset-password-success') || (loginType === 'error')) && (
            <div>
              <div className="login-area__text-gap"></div>
              {/* No Link here because we do not want to save the history under the back button and somehow Link resets loggedIn status to false */}
              <a href="/">
                <CamelCaseButton
                  variant="text"
                  className="action__link__just-text action__login__extra"
                >
                  Ok
                </CamelCaseButton>
              </a>
            </div>
          )}
        </div>
      </div>
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};
export default connect(mapStateToProps, { setLogin })(AuthLogin)