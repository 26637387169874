import "./PageContact.css";

import React, { useState } from "react";
import { connect } from 'react-redux';
import { ContactService } from "../../services/serviceContact";

import Button from '@mui/material/Button';

// ============================================================================

const PageContact = (props) => {
  const [contactEmail, setContactEmail] = useState('')
  const [contactSubject, setContactSubject] = useState('')
  const [contactText, setContactText] = useState('')

  const handleContactEmailChange = (event) => {
    const email = event.target.value;
    setContactEmail(email);
  }

  const handleContactSubjectChange = (event) => {
    const subject = event.target.value;
    setContactSubject(subject);
  }

  const handleContactTextChange = (event) => {
    const text = event.target.value;
    setContactText(text);
  }

  const handleContactSendClick = () => {
    ContactService.contact({
      email: contactEmail,
      textSubject: contactSubject,
      textDetails: contactText
    });

    setContactEmail('');
    setContactSubject('');
    setContactText('');
  }

  return (
    <div className="neumorph__panel panel-wide neumorph__surface-blank__pressed boring-page__default-block">
      <div className="boring-page__default-title">
        {props.locale.BORING___CONTACT}
      </div>
      <div className="contact-page__form">
        <div className="contact-page__text">
          <div className="contact-page__label">
            Your Email:
          </div>
          <input
            type="text"
            value={contactEmail}
            onChange={handleContactEmailChange}
          />

          <div className="contact-page__label">
            Subject:
          </div>
          <input
            type="text"
            value={contactSubject}
            onChange={handleContactSubjectChange}
          />

          <div className="contact-page__label">
            Message:
          </div>
          <textarea
            value={contactText}
            onChange={handleContactTextChange}
          />
        </div>
        <Button
          variant="contained"

          className="neumorph__surface-action__flat"
          onClick={handleContactSendClick}
        >
          SEND
        </Button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
      locale: state.locale,
  };
};

export default connect(mapStateToProps)(PageContact)