const GET_HTTP_LOG_DATA = 'GET_HTTP_LOG_DATA';
const SET_LANGUAGE = 'SET_LANGUAGE';
const GET_META = 'GET_META';
const GET_USER = 'GET_USER';
const GET_USER_UPLOADS = 'GET_USER_UPLOADS';
const GET_STRIPE_SESSION = 'GET_STRIPE_SESSION';
const SET_LOGIN = 'SET_LOGIN';
const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
const SET_FILES_SELECTED = 'SET_FILES_SELECTED';
const SET_UPLOAD_EXPIRY = 'SET_UPLOAD_EXPIRY';
const PING_TRANSFER_VIEW = 'PING_TRANSFER_VIEW';
const PING_TRANSFER_DOWNLOAD = 'PING_TRANSFER_DOWNLOAD';
const SET_UPLOAD_MEDIUM = 'SET_UPLOAD_MEDIUM';
const SET_UPLOAD_PASSWORD = 'SET_UPLOAD_PASSWORD';
const SET_UPLOAD_SECURITY_TYPE = 'SET_UPLOAD_SECURITY_TYPE';

export {
    GET_HTTP_LOG_DATA,
    GET_META,
    GET_USER,
    GET_USER_UPLOADS,
    GET_STRIPE_SESSION,
    SET_LOGIN,
    SET_SUBSCRIPTION,
    SET_LANGUAGE,
    SET_FILES_SELECTED,
    SET_UPLOAD_EXPIRY,
    PING_TRANSFER_VIEW,
    PING_TRANSFER_DOWNLOAD,
    SET_UPLOAD_MEDIUM,
    SET_UPLOAD_PASSWORD,
    SET_UPLOAD_SECURITY_TYPE,
}