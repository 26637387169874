import "./GeneralLogoSmall.css";

import React from "react";
import { Link } from "react-router-dom";

// ============================================================================

export default function GeneralLogoSmall(props) {
    return (
        <div>
            <Link to="/">
                <div
                    className="wacky-logo-small"
                    style={{
                        width: `${props.width || 32}px`,
                        height: `${props.height || 32}px`
                    }}
                ></div>
            </Link>
            <div className="wacky-status">
                BETA
            </div>
        </div>
    );
}