console.log(`
▬▬▬▬▬.◙.▬▬▬▬▬.◙.▬▬▬▬▬▬▬▬▬▬  
      ▂▄▄▓▄▄▂  
   ◢◤█▀▀████▄▄▄▄▄▄ ◢◤  
   █▄ █ █▄ ███▀▀▀▀▀▀▀ ╬  
   ◥ █████ ◤  
    ══╩══╩═  
      ╬═╬  
      ╬═╬ just dropped down to say hi
      ╬═╬ please be gentle to me,
      ╬═╬ I'm still a small service
      ╬═╬ so don't upload any crap.
      ╬═╬   
   💀/ ╬═╬   
   /▌ ╬═╬   
  ╬/\\
`);