import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from "./reducers";

// ============================================================================

// standard store (plus applyMiddleware(thunk) to allow for asyncronous state updates)
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;