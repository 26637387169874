import "./GeneralLogo.css";

import React from "react";
import { Link } from "react-router-dom";

// ============================================================================

export default function GeneralLogo() {
    return (
        <div className="wacky-logo__wrapper">
            <Link to="/">
                <div className="wacky-logo"></div>
                <div className="wacky-logo__unique-selling-point">
                    {/* Upload. Share. Forget. */}
                    Copy / Paste of the Internet.
                </div>
            </Link>
        </div>
    );
}