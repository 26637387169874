import axios from 'axios';
import { getRequestOptsAuth } from "../../services/utils.js"

import {
    SET_LOGIN,
    SET_SUBSCRIPTION,
    SET_FILES_SELECTED,
    SET_UPLOAD_MEDIUM,
    SET_UPLOAD_PASSWORD,
    SET_UPLOAD_SECURITY_TYPE,
    GET_HTTP_LOG_DATA,
    SET_LANGUAGE,
    SET_UPLOAD_EXPIRY,
    GET_META,
    GET_USER,
    GET_USER_UPLOADS,
    PING_TRANSFER_VIEW,
    PING_TRANSFER_DOWNLOAD,
    GET_STRIPE_SESSION
} from "../constants";

// ============================================================================

// Encapsulate the way you're making requests (makes it a lot easier to tweak globally)
const apiClient = axios.create({ headers: { "Content-type": "application/json" } });

// ============================================================================

function setUploadExpiry(expiryMs) {
    return {
        type: SET_UPLOAD_EXPIRY,
        payload: expiryMs
    }
}

function setUploadMedium(mediumData) {
    return {
        type: SET_UPLOAD_MEDIUM,
        payload: mediumData,
    }
}

function setUploadPassword(password) {
    return {
        type: SET_UPLOAD_PASSWORD,
        payload: { password },
    }
}

function setUploadSecurity(securityType) {
    return {
        type: SET_UPLOAD_SECURITY_TYPE,
        payload: { securityType },
    }  
}

// set selected file to upload
function setFilesSelected(payload) {
    return {
        type: SET_FILES_SELECTED,
        payload
    };
}

const getMeta = () => async (dispatch) => {
    const res = await apiClient.get('/api/v1/meta')

    dispatch({
        type: GET_META,
        payload: res.data
    });
}

const getUser = () => async (dispatch) => {
    const opts = getRequestOptsAuth();
    const res = await apiClient.get('/api/v1/user', opts)

    dispatch({
        type: GET_USER,
        payload: res.data && res.data.user,
    });
}

const getUserUploads = () => async (dispatch) => {
    const opts = getRequestOptsAuth();
    const res = await apiClient.get('/api/v1/uploads', opts)

    dispatch({
        type: GET_USER_UPLOADS,
        payload: res.data
    });
}

const setLanguage = (language) => {
    return async (dispatch) => {
        const res = await apiClient.get(`/api/v1/locale/${language}`)

        dispatch({
            type: SET_LANGUAGE,
            payload: { language, locale: res.data }
        });
    }
}

const getPaymentStripeSession = (subscriptionType) => {
    return async (dispatch) => {
        const opts = getRequestOptsAuth();
        const res = await apiClient.post(`/api/v1/payments/stripe/session/checkout`, { subscriptionType }, opts);

        dispatch({
            type: GET_STRIPE_SESSION,
            payload: { subscriptionType, data: res.data.data }
        });
    }
}

const setLogin = (username, token) => {
    if (!!username && !!token) {
        return async (dispatch) => {
            dispatch({
                type: SET_LOGIN,
                payload: { username, token }
            });
        }
    } else {
        return async (dispatch) => {
            dispatch({
                type: SET_LOGIN,
                payload: { username: null, token: null }
            });
        }
    }
}

const setSubscription = (subscription) => {
    if (!!subscription) {
        return async (dispatch) => {
            dispatch({
                type: SET_SUBSCRIPTION,
                payload: { subscription }
            });
        }
    } else {
        return async (dispatch) => {
            dispatch({
                type: SET_SUBSCRIPTION,
                payload: { subscription: null }
            });
        }
    }
}

const pingTransferStat = (uploadHash, statType) => {
    return async (dispatch) => {
        await apiClient.post(`/api/v1/file-sharing/stats`, {
            uploadHash,
            type: statType
        })

        const views = statType === 'view' ? 1 : 0;
        const downloads = statType === 'download' ? 1 : 0;

        dispatch({
            // HACK: improve the logic here (atm statType = 'random' would still count as a download)
            type: (statType === 'view') ? PING_TRANSFER_VIEW : PING_TRANSFER_DOWNLOAD,
            payload: { views, downloads }
        });
    }
}

export {
    setFilesSelected,
    getMeta,
    getUser,
    getUserUploads,
    setLanguage,
    setLogin,
    setSubscription,
    setUploadExpiry,
    setUploadMedium,
    setUploadPassword,
    setUploadSecurity,
    pingTransferStat,
    getPaymentStripeSession,
}