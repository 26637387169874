import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';

// custom stuff
import AuthLogin from "../../components/AuthLogin/AuthLogin.js";

// ============================================================================

export default function PageLogin(props) {
  let params = useParams();
  // TODO: rewrite it in a more beautiful way
  // const loginPath = props.match.path.split('/').filter(part => !!part);
  // const loginType = loginPath[1]; // after wackyupload.com/auth/login -> login
  // const registerHash = props.match.params.registerHash;
  // const resetHash = props.match.params.resetHash;
  let loginType = params.loginType;
  const registerHash = params.registerHash;
  if (registerHash) {
    loginType = 'register'
  }
  const resetHash = params.resetHash;
  if (resetHash) {
    loginType = 'reset-password'
  }

  return (
    <div>
      <AuthLogin loginType={loginType} registerHash={registerHash} resetHash={resetHash} />
    </div>
  )
}