import "./PageSubscriptionStatus.css";

// core
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import GeneralLogoSmall from "../Layout/GeneralLogoSmall/GeneralLogoSmall.js";

// custom stuff
import { setSubscription } from "../../store/actions";

// ============================================================================

// reset Capitalization of the text inside buttons
const CamelCaseButton = styled(Button)({
  textTransform: 'none',
  fontSize: 16,
  border: '1px solid',
  lineHeight: 1.5,
  borderColor: 'rgba(0,0,0,0.1)',
});

const PageSubscriptionStatus = (props) => {
    // const subscriptionPath = props.match.path.split('/').filter(part => !!part);
    // const subscriptionStatus = subscriptionPath[1]; // after wackyupload.com/subscription/success -> success
    const subscriptionStatus = props.status;

    return (
        <div className="mg20">
        <div className="subscription-area">
            <div className="subscription-area__text">
            <div className="wacky-logo__simple">
              <GeneralLogoSmall width="96" height="96" />
            </div>

                    {/* Title */}

          <div className="wacky-subscription__title">
            {((subscriptionStatus === 'success')) && (
              <span>Subscription successful!</span>
            )}
            {((subscriptionStatus === 'cancelled')) && (
              <span>Subscription cancelled</span>
            )}
          </div>

          {/* Suplementary text */}

          {((subscriptionStatus === 'success')) && (
            <div className="subscription-area__text-subsecondary">
              Your subscription plan was activated!
            </div>
          )}
          {subscriptionStatus === 'cancelled' && (
            <div className="subscription-area__text-subsecondary">
              Picked the wrong subscription? Come back and try again whenever you're ready!
            </div>
          )}

          {/* === Main CTA === */}

          <div>
            <label htmlFor="btn-subscription">
              {(subscriptionStatus === 'success') && (
                <div className="action__subscription__wrapper">
                  <Link to="/">
                    <CamelCaseButton
                      variant="contained"
                      className="neumorph__surface-action__flat action__subscription__main"
                    >
                      Ok
                    </CamelCaseButton>
                  </Link>
                </div>
              )}
              {(subscriptionStatus === 'cancelled') && (
                <div className="action__subscription__wrapper">
                  <Link to="/pricing">
                    <CamelCaseButton
                      variant="contained"
                      className="neumorph__surface-action__flat action__subscription__main"
                    >
                      Back to plans overview
                    </CamelCaseButton>
                  </Link>
                </div>
              )}
            </label>
          </div>
        </div>
      </div>
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};
export default connect(mapStateToProps, { setSubscription })(PageSubscriptionStatus)