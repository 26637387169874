import "./BackButton.css";

// core
import React from "react";
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// ============================================================================

const BackButton = (props) => {
    let history = useNavigate();

    const handleBack = () => {
      history(-1)
    }

    return (
        <div className="back-button" onClick={handleBack}>
            <ArrowBackIcon style={{ fontSize: 14 }}></ArrowBackIcon>
            <span>Back</span>
        </div>
    );
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
  };
};
export default connect(mapStateToProps)(BackButton)