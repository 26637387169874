import React from "react";
import { connect } from 'react-redux';
import GeneralHeader from "../../components/Layout/GeneralHeader/GeneralHeader.js";
import GeneralFooter from "../../components/Layout/GeneralFooter/GeneralFooter.js";

// ============================================================================

const PageFeatures = (props) => (
  // <div className="neumorph__panel panel-wide neumorph__surface-blank__pressed boring-page__default-block">
    <div>
      <GeneralHeader />

  <div className="panel-wide">
    <div className="boring-page__default-title">
      Features
    </div>
    <div className="boring-page__default-text">
      {/* <textarea value={props.locale.BORING___TERMS_OF_SERVICE_TEXT} /> */}
      <textarea defaultValue="1. No third-party tracking (we do some basic analysis of our API requests though to at least know how to optimize our website) 2. Time the expiry of your uploads, schedule the moment they get sent out 3. See if your files were viewed or downloaded 4. Single-donwload links"></textarea>
    </div>
        <GeneralFooter />
  </div>

    </div>
)

const mapStateToProps = (state) => {
  return {
      locale: state.locale,
  };
};

export default connect(mapStateToProps)(PageFeatures)