import "./PageFAQ.css"
import React from "react";
import { connect } from 'react-redux';
import GeneralHeader from "../../components/Layout/GeneralHeader/GeneralHeader.js";
import GeneralFooter from "../../components/Layout/GeneralFooter/GeneralFooter.js";

// ============================================================================

const PageFAQ = (props) => (
  // <div className="neumorph__panel panel-wide neumorph__surface-blank__pressed boring-page__default-block">
  <div>
    <GeneralHeader />

    <div className="panel-wide">
      <div className="boring-page__default-title">
        Frequently Asked Questions
      </div>

      <div className="boring-page__default-text">
        <div className="faq__paragraph faq__paragraph-title">
          1. Is Wacky Upload secure?
        </div>
        <div className="faq__paragraph">
          Yes. While we do not have a full E2E-encryption just yet, unless we, your device or your browser is compromised, your data is safe. Any external access to your data is managed by you and provided on a temporal basis (by creating a secure download link, for example).
        </div>
        <div className="faq__paragraph faq__paragraph-technical">
          Technical: Authentication flow is JWT + RS256 (RSASSA PKCS1v1.5 with SHA256), we don't store your passwords, data access is provided by you issuing either temporary public links or <a href="https://stackoverflow.com/questions/17185490/how-secure-are-presigned-urls-in-aws-s3">presigned urls</a> which are also temporary by design
          </div>
        <div className="faq__paragraph faq__paragraph-technical">
          As for the data transfer security, we are already battle-testing full E2E-encryption (or Zero-Knowledge uploads). It would secure your data all the way between your browser and the actual cloud storage in 3 steps:
          </div>
        <div className="faq__paragraph faq__paragraph-technical">
          - [1] encrypting your upload in your browser before it gets uploaded (AES-256 GCM, same Standard Amazon uses to encrypt your data in the cloud),
        </div>
        <div className="faq__paragraph faq__paragraph-technical">
          - [2] data transfer between your browser and the cloud storage happens over HTTPS (meaning the whole transport layer uses TLS, a Standard providing security against MITM attacks. In simple words, no server between you and the cloud storage has the ability to decrypt or see what data is in your transfer),
        </div>
        <div className="faq__paragraph faq__paragraph-technical">
          - [3] the storage itself is encrypted as well (AES-256 GCM) meaning that even if someone gets direct access to the storage, they wouldn't be able to decrypt your data.
        </div>
        <div className="faq__paragraph faq__paragraph-technical">
          Currently we have [2] and [3].
        </div>

        <div className="faq__paragraph faq__paragraph-title">
          2. When will you release the Beta?
        </div>
        <div className="faq__paragraph">
          Soon :)
        </div>
        <div className="faq__paragraph faq__paragraph-technical">
          Technical: We are still testing the payment gateways and various features for our flagship plan. So please don't hesitate to give us your feedback, especially at such an early stage.
        </div>

        <div className="faq__paragraph faq__paragraph-title">
          3. Do you use cookies?
        </div>
        <div className="faq__paragraph">
          No.
        </div>
        <div className="faq__paragraph faq__paragraph-technical">
          Technical: We use localStorage to keep your login sessions alive (and things like language preferences). As for the trackers in general: it's hard to improve the platform blindly. We use a cookieless tracker which monitors just the minimal amount of information needed for us to improve the service (so little that we aren't even required to have a Cookie Policy).
        </div>

        {/* <div className="faq__paragraph faq__paragraph-title">
          3. Could you please add a "Sign In With X" button?
        </div>
        <div className="faq__paragraph">
          We do not have any plans for integrating with 3-rd party authentication providers at the moment.
        </div>
        <div className="faq__paragraph faq__paragraph-technical">
          Technical: While it sounds reasonable to outsource serious things like auth to 3rd-party providers who "have all the experience", it can sometimes lead to <a href="https://news.ycombinator.com/item?id=33917962">nasty consequences</a> for the user. We don't want to have that risk.
        </div> */}
      </div>

      <GeneralFooter format="minified" />
    </div>
  </div>
)

const mapStateToProps = (state) => {
  return {
      locale: state.locale,
  };
};

export default connect(mapStateToProps)(PageFAQ)