import ReconnectingWebSocket from 'reconnecting-websocket';

// websocket that automatically reconnects
let firstConnect = null;
let lastConnect = null;
let totalConnect = null;

// wss:// protocol needs a valid SSL certificate to work so we can't use it on localhost
// Solution: https://github.com/intiface/intiface-tutorial/issues/7
// "...hidden somewhere in the docs to switch the wss:// to ws:// which fixed the problem..."
const clientDomain = window.location.hostname; // can't use just location because of 'no-restricted-globals' error
const isLocalhost = clientDomain === '127.0.0.1' || 'localhost';
const isLocalNetwork = isLocalhost || ((clientDomain.length >= 11) && (clientDomain.substring(0,8) === '192.168.'));
const clientProtocol = isLocalNetwork ? 'ws' : 'wss'; // for debugging on localhost
const clientDomainIp = clientDomain === 'localhost' ? '127.0.0.1' : clientDomain; // has to be an ip otherwise it won't open the connection
const webSocketsUrl = `${clientProtocol}://${clientDomainIp}:8087`;
const wsClient = new ReconnectingWebSocket(webSocketsUrl);

wsClient.timeoutInterval = 5000;

wsClient.onopen = function open() {
    if (!firstConnect) {
        firstConnect = new Date(); // first connection
    }
    lastConnect = new Date();
};

wsClient.onclose = function close() {
    const lastDisconnect = new Date();
    totalConnect += lastDisconnect - lastConnect;

    // print the historical downtime stats for WebSockets in a readable manner
    // (like, "there is a 0.15% chance that you miss a websocket message")

    // const failRate = (1 - (totalConnect) / (lastDisconnect - firstConnect));
    // const failRatePercentage = (100 * failRate).toFixed(2);
    // console.log(`${failRatePercentage}% chance of missing a WebSocket message`);
};

wsClient.onmessage = function incoming(e) {
    const { type, payload } = JSON.parse(e.data);

    // TODO: implement parsing WebSockets updates here
    // console.log({ type, payload })
};
