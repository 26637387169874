const utilValidateUsername = (username) => {
    // assume username is set (meaning it has a non-zero length)
    if (username.length < 3) {
        return {
            success: false,
            messageDev: 'username has to be longer than 3 characters',
            messageUser: 'Should be at least 3 characters long',
        }
    }

    if (username.length > 32) {
        return {
            success: false,
            messageDev: 'username has to be not longer than 32 characters',
            messageUser: 'Should be at most 32 characters long',
        }
    }

    /* 
        Usernames can only have: 
        - Lowercase and Uppercase Letters (A-Za-z) 
        - Numbers (0-9)
        - Dots (.)
        - Underscores (_)
    */
    const isUsername = /^[A-Za-z0-9_\.]+$/;
    if (!isUsername.test(username)) {
        return {
            success: false,
            messageDev: 'username has invalid characters',
            messageUser: 'Only letters, digits, . and _ allowed',
        }
    }

    return {
        success: true,
    }
}

const utilValidateEmail = (email) => {
    var isEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!isEmail.test(email)) {
        return {
            success: false,
            messageDev: 'email is invalid',
            messageUser: 'Invalid email',
        }
    }

    return {
        success: true,
    }
}

const utilValidatePassword = (password) => {
    // assume password is set (meaning it has a non-zero length)

    const normalCharacters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const specialCharacters = '!@#$%^&*-_';
    const chars = password.split('')
    for (let i = 0; i < chars.length; i += 1) {
        const char = chars[i];
        if (!normalCharacters.split('').includes(char) && !specialCharacters.split('').includes(char)) {
            console.log(char)
            return {
                success: false,
                messageDev: `passwords can only include numbers, english letters or special characters (${specialCharacters})`,
                messageUser: `Passwords can only include numbers, english letters or special characters (${specialCharacters})`,
            }
        }
    }

    // 8-16 characters long, at least one special character and a number
    var hasNumber = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*-_]{0,100}$/
    var hasSpecialCharacter = /^(?=.*[!@#$%^&*-_])[a-zA-Z0-9!@#$%^&*-_]{0,100}$/
    var hasEverything = /^(?=.*[0-9])(?=.*[!@#$%^&*-_])[a-zA-Z0-9!@#$%^&*-_]{8,16}$/

    if (!hasEverything.test(password)) {
        if (!hasNumber.test(password)) {
            return {
                success: false,
                messageDev: 'password has to include at least one number',
                messageUser: 'Password has to include at least one number',
            }
        } else if (!hasSpecialCharacter.test(password)) {
            return {
                success: false,
                messageDev: `password has to include a special character (${specialCharacters})`,
                messageUser: `Password has to include a special character (${specialCharacters})`,
            }
        } else {
            return {
                success: false,
                messageDev: 'password has to be 8-16 characters long',
                messageUser: 'Password has to be 8-16 characters long',
            }
        }
    }

    return {
        success: true,
    }
}

const utilValidateResetToken = (token = '') => {
    const isHexHash = /^[a-f0-9]{32}$/
    if (!isHexHash.test(token)) {
        return {
            success: false,
            messageDev: 'invalid forgot password token',
            messageUser: 'Invalid forgot password token',
        }
    }

    return {
        success: true,
    }
}

const utilValidateConfirmEmailToken = (token = '') => {
    const isHexHash = /^[a-f0-9]{32}$/
    if (!isHexHash.test(token)) {
        return {
            success: false,
            messageDev: 'invalid email confirmation token',
            messageUser: 'Invalid email confirmation token',
        };
    }

    return {
        success: true,
    }
}

export {
    utilValidateUsername,
    utilValidateEmail,
    utilValidatePassword,
    utilValidateResetToken,
    utilValidateConfirmEmailToken,
}