import "./TransferSummary.css";

// core
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

// icons
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import QrCodeScannerIcon from '@material-ui/icons/CropFree';
import PublicIcon from '@material-ui/icons/Public';
import VpnLock from '@material-ui/icons/VpnLock';

// ============================================================================

const TransferSummary = (props) => {
  const [fileExpiryLabel, setFileExpiryLabel] = useState('');

  useEffect(() => {
    const expiryMs = props.fileUploadSetting.expiry.ms;
    const uploadExpiryOption = props.uploadExpiryOptions.find(o => o.expiryMs === expiryMs);

    if (uploadExpiryOption) {
      setFileExpiryLabel(props.locale[uploadExpiryOption.id]);
    }
  }, [props.fileUploadSetting.expiry.ms, props.locale])

  return (
    <div className="transfer-summary">
      {(props.fileUploadSetting.medium === 'internet') && (
        <div>
          {props.isLoggedIn && (
            <div className="transfer-summary__item-password">
              <div>
                {!props.fileUploadSetting.password && (
                  <LockOpenIcon style={{ fontSize: 30 }}></LockOpenIcon>
                )}
                {props.fileUploadSetting.password && (
                  <LockIcon style={{ fontSize: 30 }}></LockIcon>
                )}
              </div>
            </div>
          )}
          <div className="transfer-summary__item-left">
            {!props.transferSet && (
              <div className="transfer-summary__item-line line-1">
                <DeleteSweepIcon style={{ fontSize: 16 }}></DeleteSweepIcon>
                <div className="transfer-summary__item__text">{props.locale.AUTOMATICALLY_DELETED_WITHIN} {fileExpiryLabel}</div>
              </div>
            )}
            <div className="transfer-summary__item-line line-2">
              {/* {(props.fileUploadSetting.privacyType === 'public') && (
                <PublicIcon style={{ fontSize: 16 }}></PublicIcon>
              )} */}
              {(props.fileUploadSetting.privacyType === 'private') && (
                <VpnLock style={{ fontSize: 16 }}></VpnLock>
              )}
              <div className="transfer-summary__item__text">{props.fileUploadSetting.storage.provider} , {props.fileUploadSetting.storage.location}</div>
            </div>
          </div>
        </div>
      )}

      {(props.fileUploadSetting.medium === 'qr') && (
        <div>
          <div className="transfer-summary__item-password">
            <QrCodeScannerIcon style={{ fontSize: 30 }}></QrCodeScannerIcon>
          </div>
          <div className="transfer-summary__item-left">
            <div className="transfer-summary__item-line line-2">
              {/* TODO: clean that props.locale['UPLOAD_MEDIUM_QR'] */}
              <div className="transfer-summary__item__text">{props.locale['UPLOAD_MEDIUM_QR']}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// ============================================================================

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    isLoggedIn: state.isLoggedIn,
    uploadExpiryOptions: state.uploadExpiryOptions,
    uploadSecurityOptions: state.uploadSecurityOptions,
    fileUploadSetting: state.fileUploadSetting,
  };
};
export default connect(mapStateToProps)(TransferSummary)