import "./Delimiter.css";

// core
import React from "react";
import { Link } from "react-router-dom";

// ============================================================================

const Delimiter = (props) => {
    return (
      <div className="delimiter-wrapper">
        <div className="delimiter">
          <span className="delimiter-spacer"></span>
          <span className="delimiter-text">
            {props.text}
            {props.appendix && (
              <span> (<Link className="delimiter-url" to={props.appendix.url}>{props.appendix.text}</Link>)</span>
            )}
          </span>
          <span className="delimiter-spacer"></span>
        </div>
      </div>
    );
}

export default Delimiter