import './PageUploadManager.css';

import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// custom stuff
import { UploadFilesService } from "../../services/serviceUpload.js";
import FileDownloaderLog from "../../components/FileDownloaderLog/FileDownloaderLog.js";
import { getUserUploads } from "../../store/actions";
import GeneralHeader from "../../components/Layout/GeneralHeader/GeneralHeader.js";
import GeneralFooter from "../../components/Layout/GeneralFooter/GeneralFooter.js";
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

// ============================================================================

const UploadManager = (props) => {
  const [downloadData, setDownloadData] = useState([]);

  useEffect(() => {
    props.getUserUploads();
  }, []);

  useEffect(() => {
    if (props.filesUploaded && (props.filesUploaded !== downloadData)) {
      setDownloadData(props.filesUploaded);
    }
  }, [props.filesUploaded]);

  return (
    <div>
      <GeneralHeader />

      <div className="wacky-uploader-wrapper">
        <div className="upload-manager__items">
          {downloadData.length === 0 && (
            <div>
              <SentimentVerySatisfiedIcon style={{ fontSize: 80 }}></SentimentVerySatisfiedIcon>
              <div className="upload-manager__empty-state-text">
                You have no uploads yet, <Link className="upload-manager__empty-state-link" to="/">upload</Link> something first!
              </div>
            </div>
          )}
          {downloadData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((download, index) => (
            <FileDownloaderLog key={index} downloadDataStats={download} />
          ))}
        </div>
        <GeneralFooter />
      </div>
    </div>
  )
}

// ============================================================================

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    filesUploaded: state.filesUploaded,
  };
};
export default connect(mapStateToProps, { getUserUploads })(UploadManager)